(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('ProductParametersCtrl', ProductParametersCtrl)
        .controller('ParametersGroupCtrl', ParametersGroupCtrl)
        .controller('ParameterPopupCtrl', ParameterPopupCtrl)
        .controller('ParameterLocalizationPopupCtrl', ParameterLocalizationPopupCtrl)
        .controller('Parameter2CategoryCtrl', Parameter2CategoryCtrl);

    function ProductParametersCtrl($scope, $location, $stateParams, $http, data, _env, $uibModal) {

        // GROUPS

        function getParameterGroups() {
            $http.get(_env.apiUrl + '/parameterservice/groups').then(function(response){
                $scope.groups = response.data;
            }, function(response) {});
        }

        $scope.editGroupPopup = function(group) {

			$uibModal.open({
				templateUrl: 'partials/product-parameter/group.html',
				controller: 'ParametersGroupCtrl',
				resolve: {
					group: function() {
						return group;
					},
					callback: function() {
						return getParameterGroups;
					}
				}
			});
        }

        $scope.deleteGroup = function(group) {
            var r = confirm("Удалить гурппу '"+ group.title +"'?");
            if (r == false) {
                return;
            }

            $http.delete(_env.apiUrl + '/parameterservice/groups/' + group.id).then(function(response){
                getParameterGroups();
            }, function(response) {});
        }

        getParameterGroups();



        // ALL PARAMETERS

        $scope.filter = {
            searchKey: null
        };

        function getParameters(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.receptions = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;

            var url = _env.apiUrl + '/parameterservice?withValues=true&skip=' + skip + '&max=' + $scope.pager.pageSize;
            if ($scope.filter.searchKey)
                url = url + '&searchKey=' + $scope.filter.searchKey;

            $http.get(url).then(function(response) {
                $scope.parameters = response.data.parameters;
                $scope.pager.total = response.data.totalCount;
            }, function(response){});
        }

        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 100;
        $scope.pager.getData = getParameters;

        $scope.getParameters = getParameters;

        $scope.getParameters();

        $scope.doSearch = function() {
            $scope.filter.searchKey = $scope.searchKey;
            $scope.pager.getData(true);
        }

        $scope.clearSearch = function() {
            $scope.searchKey = '';
            $scope.filter.searchKey = null;
            $scope.pager.getData(true);
        }

        $scope.openParameterPopup = function(parameter) {
            if (!parameter) {
                parameter = {};
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/product-parameter/parameter.html',
                controller: 'ParameterPopupCtrl',
                resolve: {
                    parameter: function() {
                        return parameter;
                    },
                    callback: function() {
                        return getParameters;
                    }
                }
            });
        }

        $scope.deleteParameter = function(parameter) {
            var r = confirm("Удалить параметр '"+ parameter.title +"'?");
            if (r == false) {
                return;
            }

            $http.delete(_env.apiUrl + '/parameterservice/'+parameter.id).then(function(response){
                getParameters();
            }, function(response) {});
        }

    }

    function ParameterPopupCtrl($scope, $http, $uibModalInstance, $uibModal, parameter, callback, _env) {
        $scope.newValue = {};
        $scope.parameter = parameter;
        if (!$scope.parameter.values) {
            $scope.parameter.values = [];
        }

        $scope.save = function () {
            if (!$scope.parameter.title) {
                alert('Укажите название');
                return;
            }

            if (!$scope.parameter.hasCustomValue) {
                if (!$scope.parameter.values || $scope.parameter.values.length == 0) {
                    alert('Укажите хотя бы одно значение');
                    return;
                }
            }

            if ($scope.parameter.values && $scope.parameter.values.length < 2) {
                $scope.parameter.hasMultipleChoice = false;
            }

            $http
                .post(_env.apiUrl + '/parameterservice', $scope.parameter)
                .then(function(response) {
                    callback(response.data.id);
                    $uibModalInstance.dismiss('cancel');
                }, function(response){});
        };

        $scope.addNewValue = function() {

            if (!$scope.newValue.value) return;

            var values = $scope.parameter.values;
            values.push({
                'value': $scope.newValue.value,
                'order': values.length
            });
            $scope.parameter.values = values;

            $scope.newValue = {};
        };

        $scope.deleteParameterValue = function(value) {
            var r = confirm("Удалить '"+ value.value +"'?");
            if (r == false) {
                return;
            }

            var index = $scope.parameter.values.indexOf(value);
            $scope.parameter.values.splice(index, 1);
        }

        $scope.sortableOptions = {
            stop: function(e, ui) {
                var values = $scope.parameter.values;
                for(var i = 0; i < values.length; i++) {
                    values[i].order = i;
                }

                $scope.parameter.values = values;
            }
        };

        $scope.openLocalizationPopup = function(parameterId, locale) {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/product-parameter/localization.html',
                controller: 'ParameterLocalizationPopupCtrl',
                resolve: {
                    parameterId: function() {
                        return parameterId;
                    },
                    locale: function() {
                        return locale;
                    }
                }
            });
        }
    }

    function ParameterLocalizationPopupCtrl($scope, $http, $uibModalInstance, parameterId, locale, _env) {

        var config = {
            headers: {
                'Locale': locale
            }
        };

        $http
            .get(_env.apiUrl + '/parameterservice/localization/' + parameterId, config)
            .then(function(response) {
                $scope.localization = response.data;
            });

        $scope.save = function () {
            $http
                .put(_env.apiUrl + '/parameterservice/localization/' + parameterId, $scope.localization, config)
                .then(function(response) {
                    $uibModalInstance.dismiss('cancel');
                }, function(response) {});
        };
    }

    function ParametersGroupCtrl($scope, $location, $stateParams, $http, data, _env, $uibModal) {

        $scope.group = {}
        $scope.group.parameters = [];

        function getParameters(callback) {
            $http.get(_env.apiUrl + '/parameterservice?max=1000000').then(function(response){
                $scope.parameters = response.data.parameters;

                if (callback) callback();
            }, function(response) {});
        }

        function getParameterGroup(id) {
            $http.get(_env.apiUrl + '/parameterservice/groups/' + id).then(function(response){
                $scope.group = response.data;
                getParametersByGroup();
            }, function(response) {});
        }

        function getParametersByGroup(callback) {
            if ($scope.group.id) {
                $http.get(_env.apiUrl + '/parameterservice?groupId=' + $scope.group.id).then(function(response){
                    $scope.group.parameters = response.data;
                }, function(response) {});
            }
        }

        if ($stateParams.id == 'new') {

        } else if ($stateParams.id) {
            $scope.group.id = parseInt($stateParams.id);
            getParameterGroup($scope.group.id);
        }

        getParameters();

        $scope.save = function () {
            if (!$scope.group.title) {
                return;
            }

            var parameters = _.map($scope.group.parameters, function(p) { return p.id; });
            if (!parameters) {
                return;
            }

            var group = {};
            group.id = $scope.group.id;
            group.title = $scope.group.title;
            group.parameters = parameters;

            $http
                .post(_env.apiUrl + '/parameterservice/groups', group)
                .then(function(response) {
                    alert('Successfully saved')
                }, function() {});
        };

        $scope.openParameterPopup = function() {
            var parameter = {};

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/product-parameter/parameter.html',
                controller: 'ParameterPopupCtrl',
                resolve: {
                    parameter: function() {
                        return parameter;
                    },
                    callback: function() {
                        return getParameters;
                    }
                }
            });
        }

        $scope.addParameter = function(parameter) {

            $scope.parameters = _.filter($scope.parameters, function(p) { return p.id != parameter.id; });

            if (!_.find($scope.group.parameters, function(p) { return p.id == parameter.id; })) {
                $scope.group.parameters.push(parameter);
            }

        }

        $scope.removeParameter = function(parameter) {

            $scope.group.parameters = _.filter($scope.group.parameters, function(p) { return p.id != parameter.id; });

            if (!_.find($scope.parameters, function(p) { return p.id == parameter.id; })) {
                $scope.parameters.push(parameter);
            }
        }

    }

    function Parameter2CategoryCtrl($scope, $location, $stateParams, $http, data, _env, $uibModal) {


        var parametersMap = [];

        function getParameters(callback) {

            $http.get(_env.apiUrl + '/parameterservice?max=1000000').then(function(response){

                $scope.parameters = response.data.parameters;

                _.forEach(response.data.parameters, function(parameter) {

                    parametersMap[parameter.id] = parameter;

                });

                if (callback) callback();

            }, function(response) {});

        }



        var categoriesMap = [];

        function getCategories(callback) {

            $http.get(_env.apiUrl + '/moderatorservice/categories/list/flatten').then(function(response){

                $scope.categories = response.data;

                _.forEach(response.data, function(category) {

                    categoriesMap[category.id] = category;

                });

                if (callback) callback();

            }, function(response){});

        }

        function getParameters2Categories(callback) {

            $http.get(_env.apiUrl + '/parameterservice/groups/category-to-parameter').then(function(response) {

                $scope.p2cList = response.data;

                if (callback) callback();

            }, function(response) {});

        }

        var categoryParentCache = [];

        function getCategoryWithParents(id, parents) {


            if (id) {

                var parentsFromCache = categoryParentCache[id];

                if (parentsFromCache) {

                    return parentsFromCache;

                }

            }

            if (!parents) {

                parents = [];

            }

            if (id) {

                var parent = categoriesMap[id];

                if (parent) {

                    parents.unshift(parent);

                }

                return getCategoryWithParents(parent.parentId, parents);

            } else {

                if (parents) {

                    var targetCategory = parents[parents.length - 1];

                    categoryParentCache[targetCategory.id] = parents;

                }

                return parents;

            }
        }

        $scope.getCategoryWithParents = getCategoryWithParents;

        getCategories(function() {

            getParameters(function() {

                _.forEach($scope.parameters, function(parameter) {

                    parametersMap[parameter.id] = parameter;

                });

                getParameters2Categories(function() {

                    _.forEach($scope.p2cList, function(p2c) {

                        var parameterId = p2c[0];

                        var categoryId = p2c[1];

                        if (parametersMap[parameterId].categories) {

                            parametersMap[parameterId].categories.push(categoryId);

                        } else {

                            parametersMap[parameterId].categories = [categoryId];

                        }

                    });


                    $scope.parameters = _.map($scope.parameters, function(parameter) {

                        var p = parametersMap[parameter.id];

                        p.categories = _.sortBy(p.categories);

                        return p;

                    });

                });
            });
        });

    }

})();