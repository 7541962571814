(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('Sellers2Ctrl', Sellers2Ctrl)
        .controller('SellerDetails2Ctrl', SellerDetails2Ctrl);
    
    
    function Sellers2Ctrl($scope, $http, $location, data, _env) {
        
        $scope.filter = {
            searchKey: null
        };
        
        $scope.viewSellerDetails = function(seller) {
        	$location.path("/home/seller-details/" + seller.id);
        };
        
        function getSellers(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.receptions = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/sellers/list?start=' + skip + '&max=' + $scope.pager.pageSize;
            
            if ($scope.filter.searchKey)
                url = url + '&searchKey=' + $scope.filter.searchKey;
            
            $http.get(url).then(function(response){
                $scope.sellers = response.data.sellers;
                $scope.pager.total = response.data.totalCount;
            }, function(response){});
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 50;
        $scope.pager.getData = getSellers;
        
        $scope.getSellers = getSellers;
        
        $scope.getSellers();
        
        $scope.remove = function(seller) {
            var r = confirm("Удаляя продавца вы не можете его восстановить, вы действительно хотите его удалить?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/sellers/' + seller.id).then(function(response){
                    $location.path("/home/sellers");
                }, function(response){});
            }
        };
        
        $scope.doSearch = function() {
            $scope.filter.searchKey = $scope.searchKey;
            $scope.pager.getData(true);
        }
        
        $scope.clearSearch = function() {
            $scope.searchKey = '';
            $scope.filter.searchKey = null;
            $scope.pager.getData(true);
        }
    }

    function SellerDetails2Ctrl($scope, $location, $stateParams, $http, _env, $uibModal, ROLE, data) {
    	
        $scope.$watch('$parent.admin', function(value) {
            if (value) getData();
        });
        
        function getData() {
			getSeller($stateParams.id, function() {
				getProductsForAdmin();
			});
        }
    
        function getProductsForAdmin() {
            $http
                .get(_env.apiUrl + '/moderatorservice/products/list?skip=0&max=5000&statuses=published,sold,deleted&conditions=not_used&sellerId=' + $stateParams.id)
                .then(function(response){
                    $scope.products = response.data.products;
                }, function(response){});
        }
        
        function getSeller(sellerId, callback) {
            $http
                .get(_env.apiUrl + '/moderatorservice/sellers/' + sellerId)
                .then(function(response) {
                    $scope.seller = response.data;
                    callback();
                }, function(response) {});
        }
        
        $scope.inStockItemsCount = function(product) {
			return _.reduce(_.map(product.sizes, function(item){ return item.count;}), function(memo, num){ return memo + num; }, 0);
        }
        
        $scope.soldItemsCount = function(product) {
            return _.reduce(_.map(product.sizes, function(item){ return item.soldCount;}), function(memo, num){ return memo + num; }, 0);
        }
        
        $scope.viewProductDetails = function($event, product) {
            switch ($event.which) {
                case 1:
                    $location.path("/home/edit-product/" + product.id);
                    break;
                case 2:
                    window.open("#/home/edit-product/" + product.id, '_blank');
                    break;
                default:
                    alert('You have a strange Mouse!');
            }
        };
        
        $scope.accounting = accounting;
        $scope.moment = moment;
    }
    
})();