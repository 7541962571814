(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .factory('AuthenticationService', AuthenticationService)
        .factory('data', data)
        .filter('propsFilter', propsFilter);
    
    function AuthenticationService($http, _env) {
        var service = {};

        service.Login = Login;
        service.Logout = Logout;

        return service;

        function Login(username, password, callback) {
            $http.post(_env.apiUrl + '/adminservice/login', { login: username, password: password })
                .then(function (response) {
                    callback(true);
                }, function(response) {
                    callback(false);
                });
        }

        function Logout() {
            $http.delete(_env.apiUrl + '/adminservice/logout')
                .then(function(response) {
                    
                }, function(response) {
                
                });
        }
    }
    
    function data() {
        return {
            productsPageData:{},
            usersPageData:{},
            categories:null
        };
    }
    
    function propsFilter() {
        return function(items, props) {
            var out = [];

            if (angular.isArray(items)) {
              var keys = Object.keys(props);

              items.forEach(function(item) {
                var itemMatches = false;

                for (var i = 0; i < keys.length; i++) {
                  var prop = keys[i];
                  var text = props[prop].toLowerCase();
                  if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                    itemMatches = true;
                    break;
                  }
                }

                if (itemMatches) {
                  out.push(item);
                }
              });
            } else {
              // Let the output be the input untouched
              out = items;
            }

            return out;
      };
    }
    
})();