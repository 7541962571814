(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('ReportsCtrl', ReportsCtrl)
        .controller('CreateReportsCtrl', CreateReportsCtrl)
        .controller('PrintReportCtrl', PrintReportCtrl);


    function ReportsCtrl($scope, $http, $location, data, $uibModal, _env) {

        $scope.accounting = accounting;
        $scope.moment = moment;

        function getReports() {
            $http
                .get(_env.apiUrl + '/moderatorservice/inventory-reports')
                .then(function(response) {
                    $scope.reports = response.data;
            }, function(errorResponse) {});
        }

        getReports();

        $scope.createReport = function() {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/reports/new.html',
                controller: 'CreateReportsCtrl',
                resolve: {
                    callback: function() {
                        return getReports;
                    }
                }
            });
        }

    }

    function CreateReportsCtrl($scope, $http, $uibModalInstance, $location, callback, _env) {

        $scope.accounting = accounting;
        $scope.moment = moment;
        $scope.date = moment().subtract(1, 'months').unix() * 1000;

        $scope.save = function() {
            $http
                .post(_env.apiUrl + '/moderatorservice/inventory-reports', { month: $scope.date })
                .then(function(response) {
                    if (callback) callback();
                    $uibModalInstance.dismiss('cancel');
            }, function(errorResponse) {})
        }

		$scope.onTimeSet = function(date) {
			$scope.date = date.getTime();
		}

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function PrintReportCtrl($scope, $http, $location, data, _env, $stateParams) {

        $scope.accounting = accounting;
        $scope.moment = moment;

        $http
            .get(_env.apiUrl + '/moderatorservice/inventory-reports/' + $stateParams.id)
            .then(function(response) {
                $scope.report = response.data;
                prepareTotal(response.data.products);
        }, function(errorResponse) {});

        function prepareTotal(products) {
            var startOfMonthQuantity = sum(_.map(products, function(product) { return product.quantity - product.credit + product.debit; }));
            var startOfMonthPrice = sum(_.map(products, function(product) { return product.price * (product.quantity - product.credit + product.debit); }));

            var creditQuantity = sum(_.map(products, function(product) { return product.credit; }));
            var creditPrice = sum(_.map(products, function(product) { return product.price * product.credit; }));

            var debitQuantity = sum(_.map(products, function(product) { return product.debit; }));
            var debitPrice = sum(_.map(products, function(product) { return product.price * product.debit; }));

            var endOfMonthQuantity = sum(_.map(products, function(product) { return product.quantity; }));
            var endOfMonthPrice = sum(_.map(products, function(product) { return product.price * product.quantity; }));

            $scope.report.total = {
                'startOfMonthQuantity':   startOfMonthQuantity,
                'startOfMonthPrice':      startOfMonthPrice,
                'creditQuantity':         creditQuantity,
                'creditPrice':            creditPrice,
                'debitQuantity':          debitQuantity,
                'debitPrice':             debitPrice,
                'endOfMonthQuantity':     endOfMonthQuantity,
                'endOfMonthPrice':        endOfMonthPrice
            }
        }

		function sum(numbers) {
			return _.reduce(numbers, function(memo, num){ return memo + num; }, 0)
		}

    }

})();