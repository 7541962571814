(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .directive('ngSubmenu', ngSubmenu)
        .directive('myEnter', myEnter)
        .directive('ngElevateZoom', ngElevateZoom)
        .directive('ngDatepicker', ngDatepicker)
        .directive('ngDateRangePicker', ngDateRangePicker)
        .directive('ngPagination', ngPagination)
        .directive('modal', modal)
        .directive('ngFocus', ngFocus)
        .directive('tabs', tabs)
        .directive('pane', pane);
    
    function ngSubmenu() {
        return function (scope, element, attrs) {
            element.hover(function (event) {
                $('ul',$(element)).css('display', 'block');
            }, function (event) {
                $('ul',$(element)).css('display', 'none');
            });
        };
    }

    function myEnter() {
        return function myEnter(scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if(event.which === 13) {
                    scope.$apply(function (){
                        scope.$eval(attrs.myEnter);
                    });

                    event.preventDefault();
                }
            });
        };
    }

    function ngElevateZoom() {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          //Will watch for changes on the attribute
          attrs.$observe('zoomImage',function(){
            linkElevateZoom();
          });

          function linkElevateZoom(){
            //Check if its not empty
            if (!attrs.zoomImage) return;
            element.attr('data-zoom-image',attrs.zoomImage);
            $(element).elevateZoom();
          }

          linkElevateZoom();

        }
      };
    }

    function ngDatepicker() {
        return {
            restrict: 'A',
            scope: {
              modelValue: '=ngModel'
            },
            link: function(scope, element, attrs) {            
                attrs.$observe('ngModel', function(value) {
                    var defaultDate = moment(parseFloat(scope.modelValue));
                    element.val(defaultDate.format('YYYY-MM-DD'));
                    element.datepicker({
                        format: 'yyyy-mm-dd',
                        autoclose: true,
                        defaultDate: new Date()
                    }).on('changeDate', function(e) {
                        scope.$apply(function() {
                            scope.modelValue = e.date.getTime(); 
                        });
                    });
                });
            }
        };
    }

    function ngDateRangePicker() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var start = scope.filter.dateRange.start || moment('2016-01-01');
                var end = scope.filter.dateRange.end || moment();

                element.daterangepicker({
                    locale: {
                      format: 'YYYY-MM-DD'
                    },
                    opens: 'left',
                    startDate: start,
                    endDate: end,
                    ranges: {
                       'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                       'Последный 7 дней': [moment().subtract(6, 'days'), moment()],
                       'Последный 30 дней': [moment().subtract(29, 'days'), moment()],
                       'Последный месяц': [moment().startOf('month'), moment().endOf('month')],
                       'Все время': [moment('2016-01-01'), moment()]
                    }
                }, function(start, end) {
                    scope.$apply(function(){
                        scope.filter.dateRange = {
                            start: start,
                            end: end
                        };
                    });
                });
            }
        };
    }

    function ngPagination() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var pager = scope.pager;
                if (!pager) {
                    pager = {};
                    pager.currentPage = 1;
                    pager.total = 0;
                    pager.pageSize = 10;
                }

                function initPaginator() {
                    if (pager.total == 0) {
                        element.hide();
                        return;
                    } else {
                        element.show();
                    }

                    var options = {
                        currentPage: pager.currentPage,
                        totalPages: Math.ceil(pager.total / pager.pageSize),
                        onPageClicked: function(e,originalEvent,type,page){
                            scope.$apply(function() {
                                scope.pager.currentPage = page;
                                pager.getData();
                            });
                        }
                    }

                    element.bootstrapPaginator(options);
                }

                attrs.$observe('totalPages',function() {
                    initPaginator();
                });
            }
        };
    }

    function modal() {
        return {
          template: '<div class="modal fade">' + 
              '<div class="modal-dialog">' + 
                '<div class="modal-content">' + 
                  '<div class="modal-header">' + 
                    '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>' + 
                    '<h4 class="modal-title">{{ title }}</h4>' + 
                  '</div>' + 
                  '<div class="modal-body" ng-transclude></div>' + 
                '</div>' + 
              '</div>' + 
            '</div>',
          restrict: 'E',
          transclude: true,
          replace:true,
          scope:true,
          link: function postLink(scope, element, attrs) {
            scope.title = attrs.title;

            scope.$watch(attrs.visible, function(value){
              if(value == true)
                $(element).modal('show');
              else
                $(element).modal('hide');
            });

            $(element).on('shown.bs.modal', function(){
              scope.$apply(function(){
                scope.$parent[attrs.visible] = true;
              });
            });

            $(element).on('hidden.bs.modal', function(){
              scope.$apply(function(){
                scope.$parent[attrs.visible] = false;
              });
            });
          }
        };
    }
    
    function ngFocus() {
        return {
            link: function (scope, element, attrs, $timeout ) {
                scope.$watch( attrs.ngFocus, function ( val ) {
                    if ( angular.isDefined( val ) && val ) {
//                        $timeout( function () { element[0].focus(); } );
                        setTimeout(function(){ element[0].focus(); }, 0);
                    }
                }, true);

                element.bind('blur', function () {
                    if ( angular.isDefined( attrs.ngFocusLost ) ) {
                        scope.$apply( attrs.ngFocusLost );

                    }
                });
            }
        };
    }
	
	
	
	
  function tabs() {
    return {
      restrict: 'E',
      transclude: true,
      scope: {},
      controller: [ "$scope", function($scope) {
        var panes = $scope.panes = [];
 
        $scope.select = function(pane) {
          angular.forEach(panes, function(pane) {
            pane.selected = false;
          });
          pane.selected = true;
        }
 
        this.addPane = function(pane) {
          if (panes.length == 0) $scope.select(pane);
          panes.push(pane);
        }
      }],
      template:
        '<div class="tabbable">' +
          '<ul class="nav nav-tabs">' +
            '<li ng-repeat="pane in panes" ng-class="{active:pane.selected}">'+
              '<a href="" ng-click="select(pane)">{{pane.title}}</a>' +
            '</li>' +
          '</ul>' +
          '<div class="tab-content" ng-transclude></div>' +
        '</div>',
      replace: true
    };
  }
  
  function pane() {
    return {
      require: '^tabs',
      restrict: 'E',
      transclude: true,
      scope: { title: '@' },
      link: function(scope, element, attrs, tabsCtrl) {
        tabsCtrl.addPane(scope);
      },
      template:
        '<div class="tab-pane" ng-class="{active: selected}" ng-transclude>' +
        '</div>',
      replace: true
    };
  }

})();