(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('BannersCtrl', BannersCtrl);
    

    function BannersCtrl($scope, $location, $http, _env) {
        
        // header banner logic
        $http
            .get(_env.apiUrl + '/moderatorservice/banners?id=1')
            .then(function(response){
                $scope.headerBanner = response.data;
            }, function(response){});
        
        $scope.saveHeaderBanner = function() {
            if (!$scope.headerBanner.image) {
                alert('Header banner image is required!');
                return;
            };
            
            if (!$scope.headerBanner.link) {
                //alert('Header banner link is required!');
                //return;
                $scope.headerBanner.link = "";
            };

            $http
                .put(_env.apiUrl + '/moderatorservice/banners', $scope.headerBanner)
                .then(function(response){
                        alert('Successfully saved');
                    }, function(response){
                });
        }
        
        var logo = $($('#image-file')[0]);		
        logo.bind('change', function(){
            var file = $('#image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response){
                        $scope.headerBanner.image= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        var logo = $($('#mobile-image-file')[0]);		
        logo.bind('change', function(){
            var file = $('#mobile-image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response){
                        $scope.headerBanner.mobileImage= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        // footer banner logic
        $http
            .get(_env.apiUrl + '/moderatorservice/banners?id=2')
            .then(function(response){
                $scope.footerBanner = response.data;
            }, function(response){});
        
        $scope.saveFooterBanner = function() {
            if (!$scope.footerBanner.image) {
                alert('Footer banner image is required!');
                return;
            };
            
            if (!$scope.footerBanner.link) {
                //alert('Footer banner link is required!');
                //return;
                $scope.footerBanner.link = "";
            };

            $http
                .put(_env.apiUrl + '/moderatorservice/banners', $scope.footerBanner)
                .then(function(response){
                        alert('Successfully saved');
                    }, function(response){
                });
        }
        
        var logo = $($('#footer-image-file')[0]);		
        logo.bind('change', function(){
            var file = $('#footer-image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response){
                        $scope.footerBanner.image= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        var logo = $($('#mobile-footer-image-file')[0]);		
        logo.bind('change', function(){
            var file = $('#mobile-footer-image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response){
                        $scope.footerBanner.mobileImage= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        // reception banner logic
        $http
            .get(_env.apiUrl + '/moderatorservice/banners?id=3')
            .then(function(response){
                $scope.receptionBanner = response.data;
            }, function(response){});
        
        $scope.saveReceptionBanner = function() {
            if (!$scope.receptionBanner.image) {
                alert('Reception banner image is required!');
                return;
            };
            
            if (!$scope.receptionBanner.link) {
                //alert('Footer banner link is required!');
                //return;
                $scope.receptionBanner.link = "";
            };

            $http
                .put(_env.apiUrl + '/moderatorservice/banners', $scope.receptionBanner)
                .then(function(response){
                        alert('Successfully saved');
                    }, function(response){
                });
        }
        
        var logo = $($('#reception-image-file')[0]);		
        logo.bind('change', function(){
            var file = $('#reception-image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response){
                        $scope.receptionBanner.image= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        var logo = $($('#mobile-reception-image-file')[0]);		
        logo.bind('change', function(){
            var file = $('#mobile-reception-image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response){
                        $scope.receptionBanner.mobileImage= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        })
    }
})();