(function () {
    'use strict';
    
    angular
        .module('bulavka-console')
        .controller('HomeCtrl', HomeCtrl);
    
    var
        categories = MenuItem('home/category-list', 'Категории'),
        products = MenuItem('home/products-list', 'Товары'),
        pendingProducts = MenuItem('home/pending-products', 'Новые товары'),
        collections = MenuItem('home/editors-picks', 'Подборки'),
        banners = MenuItem('home/banners', 'Баннеры'),
        users = MenuItem('home/users', 'Пользователи'),
        sellers2 = MenuItem('home/sellers2', 'Комитенты (товары)'),
        sellers = MenuItem('home/sellers', 'Комитенты'),
        brands = MenuItem('home/brands', 'Бренды'),
        orders = MenuItem('home/orders', 'Заказы'),
        accounts = MenuItem('home/accounts', 'Менеджеры'),
        stat = MenuItem('home/stat', 'Статистика'),
        sales = MenuItem('home/sales', 'Продажи'),
        reports = MenuItem('home/reports', 'Отчеты'),
        productParameters = MenuItem('home/product-parameters', 'Параметры товаров'),
        sms = MenuItem('home/sms', 'СМС'),
        inventoryProducts = MenuItem('home/inventory-products', 'Товары'),
        shippingSettings = MenuItem('home/shipping-settings', 'Параметры доставки');
    
    var ROLE = {
        ADMIN:              'admin',
        CONTENT_MANAGER:    'content_manager',
        ORDER_MANAGER:      'order_manager',
        SEO_MANAGER:        'seo_manager',
        FINANCE_MANAGER:    'finance_manager'
    }
    angular
        .module('bulavka-console')
        .constant('ROLE', ROLE);
    
    function MenuItem(path, title) {
        return {
            path: path,
            title: title
        };
    }
    
    function makeAsLanding(menuItem) {
        menuItem.isLanding = true;
        return menuItem;
    }
        
    var ROLES = {}
    ROLES[ROLE.ADMIN] = {
        'accesses': [
			categories,
			pendingProducts,
			makeAsLanding(products),
			collections,
			brands,
			orders,
			sellers2,
			sellers,
			users,
			accounts,
			sales,
			stat,
			reports,
			productParameters,
			shippingSettings,
			sms
		]
    };
    ROLES[ROLE.CONTENT_MANAGER] = {
        'accesses': [
			categories,
			pendingProducts,
			makeAsLanding(products),
			collections,
			orders,
			brands,
			sellers2,
			productParameters,
			shippingSettings
		]
    };
    ROLES[ROLE.ORDER_MANAGER] = {
        'accesses': [
			makeAsLanding(orders),
			users
		]
    };
    ROLES[ROLE.SEO_MANAGER] = {
        'accesses': [
			categories,
			collections,
			makeAsLanding(brands)
		]
    };
    ROLES[ROLE.FINANCE_MANAGER] = {
        'accesses': [
            makeAsLanding(inventoryProducts),
			sellers,
			sales,
            reports
		]
    }
    
    function HomeCtrl($scope, $location, $http, AuthenticationService, _env, data, resolveCategories) {
        
        $scope.menuItems = [];
        
        $http.get(_env.apiUrl + '/adminservice/profile').then(function(response) {
            $scope.admin = response.data;
            $scope.admin.hasRole = function(role) {
                return role == data.currentUser.role;
            }
            data.currentUser = $scope.admin
            ;
            $scope.menuItems = ROLES[$scope.admin.role].accesses;

            if ($location.$$path == '/home') {
                var landingView = _.find($scope.menuItems, function(menuItem) { return menuItem.isLanding; });
                $location.path(landingView.path);
            }
        });
        
		$scope.toggleMenuItem = function(clickEvent) {
            $('#side-menu a').removeClass('active');
            $(clickEvent.target).addClass('active');
        }
				
        $scope.logout = function() {
            AuthenticationService.Logout();
            $location.path('/login');
        }
    }
})();