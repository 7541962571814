(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('TestCtrl', TestCtrl);
    
    function TestCtrl($scope, $location, $http, $stateParams, _env, data) {
        
		$scope.items = [];
        $scope.deleteCategory = function(index) {
            $scope.items.splice(index, 1);
        }

        $scope.addCategory = function() {
			var categories = angular.copy(data.categories);
            $scope.items.push({
                categories: categories,
				category: null
            });
        }
        
    }
    
})();