(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('UsersCtrl', UsersCtrl)
        .controller('UserDetailsCtrl', UserDetailsCtrl)
        .controller('SendSmsCtrl', SendSmsCtrl);
    
    function UsersCtrl($scope, $http, $location, data, _env) {
        
        $scope.filter = {
            searchKey: null
        };
        
        $scope.viewUserDetails = function(user) {
        	$location.path("/home/user-details/" + user.id);
        };
        
        function getUsers(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.receptions = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/users/list?start=' + skip + '&max=' + $scope.pager.pageSize;
            
            if ($scope.filter.searchKey)
                url = url + '&searchKey=' + $scope.filter.searchKey;
            
            $http.get(url).then(function(response){
                $scope.users = response.data.users;
                $scope.pager.total = response.data.totalUsers;
            }, function(response){});
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 50;
        $scope.pager.getData = getUsers;
        
        $scope.getUsers = getUsers;
        
        $scope.getUsers();
        
        $scope.remove = function(user) {
            var r = confirm("Удаляя покупателя вы не можете его восстановить, вы действительно хотите его удалить?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/users/delete?id=' + user.id).then(function(response){
                    $location.path("/home/users");
                }, function(response){});
            }
        };
        
        $scope.doSearch = function() {
            $scope.filter.searchKey = $scope.searchKey;
            $scope.pager.getData(true);
        }
        
        $scope.clearSearch = function() {
            $scope.searchKey = '';
            $scope.filter.searchKey = null;
            $scope.pager.getData(true);
        }
        
        $scope.accounting = accounting;
        $scope.moment = moment;
        
    }

    function UserDetailsCtrl($scope, $location, $stateParams, $http, _env) {
    	
        $http.get(_env.apiUrl + '/moderatorservice/users/profile/' + $stateParams.id).then(function(response){
            $scope.user = response.data;
        }, function(response) {});
    		
        $scope.getDateStr = function(date) {
        	return new Date(date).toLocaleString();
        };
        
        $scope.save = function() {            
            var user = {};
            user.id = $scope.user.id;
            user.firstName = $scope.user.firstName.trim();
            user.lastName = $scope.user.lastName.trim();
            if ($scope.user.middleName)
                user.middleName = $scope.user.middleName.trim();
            user.gender = $scope.user.gender.trim();
            
            user.birthday = $scope.user.birthday.trim();
            user.isConfirmed = $scope.user.isConfirmed;
            
            if (!user.firstName) {
                alert('Укажите имя покукателя');
                return;
            }
            
//            if (!user.lastName) {
//                alert('Укажите фамилия покукателя');
//                return;
//            }
//            
//            if (!user.gender) {
//                alert('Укажите пол покукателя');
//                return;
//            }
//            
//            if (!user.birthday) {
//                alert('Укажите дата рождения покукателя');
//                return;
//            }

            $http.put(_env.apiUrl + '/moderatorservice/users/update', user).then(function(response) {
                $location.path("/home/users");
            }, function(response) {});
        };
        
        $scope.remove = function() {
            var r = confirm("Удаляя покупателя вы не можете его восстановить, вы действительно хотите его удалить?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/users/delete?id=' + $scope.user.id).then(function(response){
                    $location.path("/home/users");
                }, function(response){});
            }
        };
    }

    function SendSmsCtrl($scope, $http, _env) {

        $http.get(_env.apiUrl + '/moderatorservice/phones').then(function(response) {
            $scope.phoneNumbers = response.data.join('\n');
        }, function(response) {});

        $scope.send = function() {            
            var data = {};
            data.text = $scope.text;
            data.phonesNumbers = _.map($scope.phoneNumbers.split(/\n/), function(str){ return str.trim(); });
            
            if (!data.text) {
                alert('Укажите текст');
                return;
            }
            
            if (!data.phonesNumbers) {
                alert('Укажите номера');
                return;
            }

            $http.post(_env.apiUrl + '/moderatorservice/users/sendsms', data).then(function(response) {
                alert("Отправка успешно запланирована.");
            }, function(response) {});
        };
    }
})();