(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('CategoryListCtrl', CategoryListCtrl)
        .controller('CategoryDetailsCtrl', CategoryDetailsCtrl)
        .controller('CategoryLocalizationPopupCtrl', CategoryLocalizationPopupCtrl);
    
    function CategoryListCtrl($scope, $http, $location, data, _env) {
    	
        $http.get(_env.apiUrl + '/moderatorservice/categories/list').then(function(response){
            $scope.categories = response.data;
            data.categories = response.data;
        }, function(response){});
        
        $scope.viewCategoryDetails = function(category) {
        	$location.path("/home/category-details/" + category.id);
        };
        
        $scope.sortableOptions = {
            stop: function(e, ui) {
                var i = 0;
                var categoryOrders = _.map($scope.categories, function(category) {
                    var order = { id: category.id, order: i };
                    i++;
                    return order;
                });
                $http.put(_env.apiUrl + '/moderatorservice/categories/update_orders', categoryOrders);
            }
        };
    }

    function CategoryDetailsCtrl($scope, $location, $stateParams, $http, data, _env, $uibModal) {
        
        $scope.category = {};
        
        $scope.focused = true;
        
        if ($stateParams.id) {
            $http.get(_env.apiUrl + '/moderatorservice/categories/details?id=' + $stateParams.id).then(function(response){
                $scope.category = response.data;
				prepareParents();
            }, function(){});
            getParameters();
        } else if ($location.$$search.parentId) {
            $scope.category.parentId = parseInt($location.$$search.parentId);
            $scope.parameters = [];
			prepareParents();
        }
		
		function prepareParents() {
            $http.get(_env.apiUrl + '/moderatorservice/categories/list/flatten').then(function(response){
                var categories = response.data;
				var parents = [];
				var parentId = $scope.category.parentId;
				while(parentId) {
					var parent = _.find(categories, function(category) {
						return category.id == parentId;
					});
					if (parent) {
						parents.unshift(parent);
						parentId= parent.parentId;
					}
				}
				$scope.parents = parents;
            }, function(){});
		}
        
        $scope.save = function() {
            if (!$scope.category.title) {
                alert('Укажите название под категорий');
                return;
            }
            
            if (!$scope.category.slug) {
                $scope.category.slug = $scope.category.title;
            }
            
            if (!$scope.category.h1) {
                alert('Укажите h1 загаловок');
                return;
            }
            
            if (!$scope.category.h2)
                $scope.category.h2 = '';
            
            if (!$scope.category.description)
                $scope.category.description = '';

            if (!$scope.category.image) {
                $scope.category.image = {
                    filename: '',
                    image: ''
                }
            };
            
            if ($scope.category.sale && ($scope.category.sale < 0 || $scope.category.sale > 100)) {
                alert('Не правильная скидка');
                return;
            }
            
            $scope.category.categoryId = $scope.category.id;
            
            $http.put(_env.apiUrl + '/moderatorservice/categories/update', $scope.category).then(function(response){
                alert('Successfully saved');
                $scope.category.id = response.data.id;
            }, function(response) {});
        };

        var image = $($('#image-file')[0]);
        image.bind('change', function(){
            var file = $('#image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=other', c, config).then(function(response) {
                        $scope.category.image= response.data;
                    }, function(response) {
                        alert(response.data);
                    });
                };
                fr.readAsArrayBuffer(file);
        });

        $scope.viewSubCategoryDetails = function(subCategory) {
            $location.path("/home/category-details/" + subCategory.id);
        }
        
        $scope.delete = function() {
        	if ($scope.category.productsCount > 0) {
        		alert("Нельзя удалить под котегорию с товарами.");
        	} else {
                var r = confirm("Удалить запись?");
                if (r == true) {                
                    $http.delete(_env.apiUrl + '/moderatorservice/categories/delete?id=' + this.category.id).then(function(response){
                        history.back();
                    }, function(response){});
                }
            }
        };
        
        $scope.sortableOptions = {
            stop: function(e, ui) {
                var i = 0;
                var categoryOrders = _.map($scope.category.children, function(category) {                    
                    var order = { id: category.id, order: i };
                    i++;
                    return order;
                });
                $http.put(_env.apiUrl + '/moderatorservice/categories/update_orders', categoryOrders);
            }
        };

        $scope.isParametersAccessable = function() {
            return $stateParams.id && ($scope.category.children == null || $scope.category.children.length == 0);
        }

        function getParameters() {
            $http.get(_env.apiUrl + '/parameterservice/categories/'+$stateParams.id).then(function(response){
                $scope.parameters = response.data;
            }, function(response) {});
        }

        $scope.deleteParameter = function(parameterToDelete) {
            var r = confirm("Удалить параметр '"+ parameterToDelete.title +"'?");
            if (r == false) {
                return;
            }

            $scope.parameters = _.filter($scope.parameters, function(parameter) {
                return parameter.id != parameterToDelete.id;
            });

            saveParameters();
        }

        function saveParameters(callback) {
            var i = 0;
            var orderedParametersData = _.map($scope.parameters, function(parameter) {
                var orderedParameterData = { parameterId: parameter.id, order: i, isFilter: parameter.isFilter ? parameter.isFilter : false };
                i++;
                return orderedParameterData;
            });

            var data = {
                categoryId: $scope.category.id,
                parameters: orderedParametersData
            };

            $http.post(_env.apiUrl + '/parameterservice/categories', data).then(function(){
                if (callback) callback();
            });
        }

        $scope.saveParameters = saveParameters;

        $scope.parameterSortableOptions = {
            stop: function(e, ui) {
                saveParameters();
            }
        };

        $scope.editParametersCallback = function(selectedParameters) {
            var selectedParameterIds = _.map(selectedParameters, function(p) { return p.id; });
            var cleanedLocalParameters = _.filter($scope.parameters, function(p) { return _.contains(selectedParameterIds, p.id); });

            var cleanedLocalParameterIds = _.map(cleanedLocalParameters, function(p) { return p.id; });
            var nonExistsParameters = _.filter(selectedParameters, function(p) { return !_.contains(cleanedLocalParameterIds, p.id); });

            $scope.parameters = cleanedLocalParameters.concat(nonExistsParameters);

            saveParameters(getParameters);

            $scope.editParameters = false;
        }

        $scope.openParameterPopup = function(parameter) {
            if (!parameter) {
                parameter = {};
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/product-parameter/parameter.html',
                controller: 'ParameterPopupCtrl',
                resolve: {
                    parameter: function() {
                        return parameter;
                    },
                    callback: function() {
                        return getParameters;
                    }
                }
            });
        }

        $scope.openLocalizationPopup = function(categoryId, locale) {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/category/localization.html',
                controller: 'CategoryLocalizationPopupCtrl',
                resolve: {
                    categoryId: function() {
                        return categoryId;
                    },
                    locale: function() {
                        return locale;
                    }
                }
            });
        }
    }

    function CategoryLocalizationPopupCtrl($scope, $http, $uibModalInstance, categoryId, locale, _env) {

        var config = {
            headers: {
                'Locale': locale
            }
        };

        $http
            .get(_env.apiUrl + '/moderatorservice/categories/' + categoryId + '/localization', config)
            .then(function(response) {
                $scope.localization = response.data;
            });

        $scope.save = function () {
            $http
                .put(_env.apiUrl + '/moderatorservice/categories/' + categoryId + '/update/localization', $scope.localization, config)
                .then(function(response) {
                    $uibModalInstance.dismiss('cancel');
                }, function(response) {});
        };
    }
    
})();