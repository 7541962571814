(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('LoginCtrl', LoginCtrl);
    
    function LoginCtrl($scope, $location, AuthenticationService, _env) {
        
        $scope.login = function() {
            AuthenticationService.Login($scope.username, $scope.password, function (result) {
                if (result === true) {
                    $location.path('/home');
                } else {
                    $scope.error = 'Username or password is incorrect';
                    $scope.loading = false;
                }
            });
        };
        
    }
})();