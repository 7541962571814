(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('ShippingSettingsCtrl', ShippingSettingsCtrl)
        .controller('RegionPopupCtrl', RegionPopupCtrl)
        .controller('CityPopupCtrl', CityPopupCtrl);

    function ShippingSettingsCtrl($scope, $location, $uibModal, $http, _env) {

        function getData() {
            $http
                .get(_env.apiUrl + '/orderservice/shipping/regions')
                .then(function(response) {
                    $scope.regions = response.data;
                }, function(response){});
        }
        getData();

        $scope.accounting = accounting;

        $scope.openRegionPopup = function(region) {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/shipping-settings/region.html',
                controller: 'RegionPopupCtrl',
                resolve: {
                    region: function() {
                        return region;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.openCityPopup = function(city, region) {
            if (!city) {
                city = {};

                city.isCODAllowed = false;
                city.shippingCost = 0.0;
                city.freeShippingSettings = {
                    enabled: false,
                    minPrice: 0.0
                };
            }
            city.regionId = region.id;

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/shipping-settings/city.html',
                controller: 'CityPopupCtrl',
                resolve: {
                    city: function() {
                        return city;
                    },
                    region: function() {
                        return region;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.deleteCity = function(city) {
            var r = confirm("Удалить город '"+ city.title +"'?");
            if (r == false) {
                return;
            }

            $http.delete(_env.apiUrl + '/moderatorservice/shipping/cities/' + city.id).then(function(response){
                getData();
            }, function(response) {});
        }
    }
    
    function RegionPopupCtrl($scope, $http, $uibModalInstance, region, _env, callback) {
        $scope.region = region;
        $scope.newCustomizableShippingCost = region.customizableShippingCost;

        $scope.save = function() {
            $scope.region.customizableShippingCost = $scope.newCustomizableShippingCost;
            $http
                .put(_env.apiUrl + '/moderatorservice/shipping/regions/' + $scope.region.id, $scope.region)
                .then(function(response) {
                    callback();
                    $uibModalInstance.dismiss('cancel');
                }, function(response){});
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function CityPopupCtrl($scope, $http, $uibModalInstance, city, region, _env, callback) {
        $scope.region = region;
        $scope.city = city;

        $scope.editableCity = {
            regionId: city.regionId,
            id: city.id,
            title: city.title,
            shippingCost: city.shippingCost,
            isCODAllowed: city.isCODAllowed,
            freeShippingSettings: {
                enabled:  city.freeShippingSettings.enabled,
                minPrice: city.freeShippingSettings.minPrice
            }
        };

        $scope.save = function() {
            if (!$scope.editableCity.title) {
                alert('Укажите название');
                return;
            }

            $scope.editableCity.isCODAllowed = $scope.editableCity.isCODAllowed ? $scope.editableCity.isCODAllowed : false;

            if ($scope.editableCity.id) {
                $http
                    .put(_env.apiUrl + '/moderatorservice/shipping/cities/' + $scope.editableCity.id, $scope.editableCity)
                    .then(function(response) {
                        callback();
                        $uibModalInstance.dismiss('cancel');
                    }, function(response){});
            } else {
                $http
                    .post(_env.apiUrl + '/moderatorservice/shipping/cities', $scope.editableCity)
                    .then(function(response) {
                        callback();
                        $uibModalInstance.dismiss('cancel');
                    }, function(response){});
            }
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
    
})();