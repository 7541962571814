(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('StatCtrl', StatCtrl);
    
    function StatCtrl($scope, $location, $http, _env) {
        
        $http.get(_env.apiUrl + '/moderatorservice/orders/stat').then(function(response) {
            $scope.items = response.data;
        
            var ios = [];
            var android = [];
            var web = [];    
            
            _.forEach($scope.items, function(item) {
                if (item.status == 'done') {
                    if (item.platform == 'ios') {
                        ios.push(item.orderId);
                    } else if (item.platform == 'android') {
                        android.push(item.orderId);
                    } else {
                        web.push(item.orderId);
                    }
                }
            });
            
            $scope.total = {
                ios: {
                    items: ios.length,
                    orders: _.uniq(ios).length
                },
                android: {
                    items: android.length,
                    orders: _.uniq(android).length
                },
                web: {
                    items: web.length,
                    orders: _.uniq(web).length
                }
            }
            
            initChart();
        });
        
        function initChart() {
            
            var filteredItems = _.filter($scope.items, function(item) { return item.status == 'done'; });
            
            var minDate = moment().subtract(1, 'month');
            filteredItems = _.filter(filteredItems, function(item) { return item.created > minDate; });
            
            var dateFormat = 'YYYY-MM-DD';
            var preparedItems = _.map(filteredItems, function(item) {
                item.createdStr = moment(item.created).format(dateFormat);
                return item;
            });
            
            var groupedItems = _.groupBy(preparedItems, function(item) { return item.createdStr; });
            
            var chartData = _.map(_.pairs(_.mapObject(groupedItems, function(val, key) {
              return val.length;
            })), function(item) { return { x: moment(item[0], dateFormat), y: item[1]}; });
            var labels = _.map(chartData, function(item) { return item.x; });

            var ctx = document.getElementById("myChart").getContext("2d");
            ctx.canvas.width = 1000;
            ctx.canvas.height = 300;
            var myBarChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
    					label: "Last 30 days",
                        data: chartData,
                        type: 'bar',
                        pointRadius: 1,
                        fill: true,
                        lineTension: 0,
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        xAxes: [{
                            type: 'time',
                            distribution: 'series',
                            ticks: {
                                source: 'labels'
                            }
                        }]
                    }
                }
            });
            
        }
        
    }

})();