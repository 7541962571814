(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('SellerSalesCtrl', SellerSalesCtrl)
        .controller('SalesCtrl', SalesCtrl);

	function SellerSalesCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

	    $scope.seller = {};
	    $scope.seller.id = $stateParams.id;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}

        $scope.accounting = accounting;
        $scope.moment = moment;

        function getSaleHistory() {
            var url = _env.apiUrl + '/moderatorservice/sale_reports/unreported_sales?sellerId=' + $scope.seller.id;
            $http.get(url).then(function(response){
                $scope.soldItems = response.data;
            }, function(response) { });

        }

        getSaleHistory();

        $scope.acceptPayment = function(item) {
            var data = {
                itemId: item.id,
                orderId: item.orderId,
                isPaymentAccepted: true
            };
            $http.put(_env.apiUrl + '/moderatorservice/orders/update_item_payment_accepted', data).then(function(response) {
                item.isPaymentAccepted = true;
            }, function(response) {});
        }
		
		$scope.$parent.createReportPopup = function() {
			var report = {
				otherExpense: 0,
				balanceDebts: 0,
				alreadyPaid: 0,
				products: [],
				date: (new Date()).getTime()
			};

			report.sellerId = parseInt($scope.seller.id);

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/report/new.html',
                controller: 'CreateSaleReportCtrl',
                resolve: {
                    report: function() {
                        return report;
                    },
                    callback: function() {
                        return getSaleHistory;
                    }
                }
            });
        }

	}
    
    function SalesCtrl($scope, $location, data, $http, _env) {
        
        $scope.filter = {
            searchKey: null
        };
        
        function getItems(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.receptions = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/orders/sale_history?simpleRange=all&skip=' + skip + '&max=' + $scope.pager.pageSize;
            if ($scope.filter.searchKey)
                url = url + '&searchKey=' + $scope.filter.searchKey;
            
            $http.get(url).then(function(response) {
                $scope.items = response.data.products;
                $scope.pager.total = response.data.totalCount;
            }, function(response){});
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 50;
        $scope.pager.getData = getItems;
        
        $scope.getItems = getItems;
        
        $scope.getItems();
        
        $scope.accounting = accounting;
        $scope.moment = moment;
        
        $scope.acceptPayment = function(item) {
            var data = {
                itemId: item.id,
                orderId: item.orderId,
                isPaymentAccepted: true
            };
            $http.put(_env.apiUrl + '/moderatorservice/orders/update_item_payment_accepted', data).then(function(response) {
                item.isPaymentAccepted = true;
            }, function(response) {});
        }
		
		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}
        
        $scope.doSearch = function() {
            $scope.filter.searchKey = $scope.searchKey;
            $scope.pager.getData(true);
        }
        
        $scope.clearSearch = function() {
            $scope.searchKey = '';
            $scope.filter.searchKey = null;
            $scope.pager.getData(true);
        }

    }
    
    
})();