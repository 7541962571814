(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('AccountsCtrl', AccountsCtrl)
        .controller('AccountCtrl', AccountCtrl);
    
    function AccountsCtrl($scope, $location, $http, _env) {
        
        $http.get(_env.apiUrl + '/adminservice/admins').then(function(response) {
            $scope.accounts = response.data;
        });
        
        $scope.humanReadableRole = function(role) {
            if (role == 'admin') {
                return 'Admin';
            } else if (role == 'content_manager') {
                return 'Content Manager';
            } else if (role == 'order_manager') {
                return 'Sales Manager';
            } else if (role == 'seo_manager') {
                return 'Seo Manager';
            } else if (role == 'finance_manager') {
                return 'Finance Manager';
            }
        }
        
        $scope.showAccountDetails = function(account) {
            $location.path('/home/account/' + account.id);
        }
    }
    
    function AccountCtrl($scope, $location, $http, $stateParams, _env) {

        $scope.admin = {};
        $scope.isNewAccount = $location.$$path == '/home/new-account';
        $scope.isRoleEditable = $scope.isNewAccount || $stateParams.id;
            
        if ($stateParams.id) {
            $http.get(_env.apiUrl + '/adminservice/profile/' + $stateParams.id).then(function(response) {
                $scope.admin = response.data;
            });
        } else if (!$scope.isNewAccount) {
            $http.get(_env.apiUrl + '/adminservice/profile').then(function(response) {
                $scope.admin = response.data;
                $scope.isRoleEditable = $scope.admin.role == 'admin';
            });
        }
        
        $scope.save = function() {
            if (!$scope.admin.name) {
                alert('Name is required!');
                return;
            }
            
            var data = {};
            
            if (!$scope.isNewAccount) {
                data.id = $scope.admin.id;
            } else {
                data.login = $scope.admin.login;
            
                if (!$scope.admin.login) {
                    alert('Login is required!');
                    return;
                }
            }
            
            if (!$scope.admin.role) {
                alert('Role is required!');
                return;
            }
            
            data.name = $scope.admin.name;
            data.role = $scope.admin.role;
            
            if ($scope.isNewAccount && !$scope.admin.newPass) {
                alert('Incorrect password!');
                return;
            }
            
            if ($scope.admin.newPass || $scope.admin.confirmNewPass) {
                if ($scope.admin.newPass != $scope.admin.confirmNewPass) {
                    alert('Password not match!');
                    return;
                }
                
                data.password = $scope.admin.newPass;
            }

            if ($scope.isNewAccount) {
                $http.post(_env.apiUrl + '/adminservice/add', data).then(function(response){
                    $location.path('/home/accounts');
                }, function(response){
                    alert(response.data);
                });
            } else {
                $http.put(_env.apiUrl + '/adminservice/update', data).then(function(response){
                    $location.path('/home/accounts');
                }, function(response){
                    alert(response.data);
                });
            }
        }
        
        $scope.remove = function() {
            var r = confirm("Delete account?");
            if (r == true) {                
                $http.delete(_env.apiUrl + '/adminservice/delete/' + $scope.admin.id).then(function(response){
                    $location.path('/home/accounts');
                }, function(response) {});
            }
        }

    }

})();