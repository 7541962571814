(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('SellersCtrl', SellersCtrl)
        .controller('SellerCtrl', SellerCtrl);


    function SellersCtrl($scope, $http, $location, data, _env) {

        $scope.filter = {
            searchKey: null
        };

        $scope.viewSellerDetails = function(seller) {
        	$location.path("/home/seller-details/" + seller.id);
        };

        function getSellers(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.receptions = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;

            var url = _env.apiUrl + '/moderatorservice/sellers/list?start=' + skip + '&max=' + $scope.pager.pageSize;

            if ($scope.filter.searchKey)
                url = url + '&searchKey=' + $scope.filter.searchKey;

            $http.get(url).then(function(response){
                $scope.sellers = response.data.sellers;
                $scope.pager.total = response.data.totalCount;
            }, function(response){});
        }

        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 50;
        $scope.pager.getData = getSellers;

        $scope.getSellers = getSellers;

        $scope.getSellers();

        $scope.remove = function(seller) {
            var r = confirm("Удаляя продавца вы не можете его восстановить, вы действительно хотите его удалить?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/sellers/' + seller.id).then(function(response){
                    $location.path("/home/sellers");
                }, function(response){});
            }
        };

        $scope.doSearch = function() {
            $scope.filter.searchKey = $scope.searchKey;
            $scope.pager.getData(true);
        }

        $scope.clearSearch = function() {
            $scope.searchKey = '';
            $scope.filter.searchKey = null;
            $scope.pager.getData(true);
        }
    }

	function SellerCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

		$scope.seller = {};
		
		if ($stateParams.id == -1) {
			$stateParams.id = null;
		} else {
			$scope.seller.id = $stateParams.id;
			
			$http
				.get(_env.apiUrl + '/moderatorservice/sellers/' + $scope.seller.id)
				.then(function(response) {
					$scope.seller = response.data;
				}, function(response) {});
		}
		
		$scope.isCurrentTab = function(tab) {
			return $scope.currentTab && $scope.currentTab.id == tab.id;
		}
		
		$scope.setCurrentTab = function(tab) {
			if (!tab) {
				tab = $scope.tabs[0];
			}
			
			$scope.currentTab = tab;
		}
		
		if ($scope.seller.id) {
			$scope.tabs = [{
				id: 			0,
				title:			'Основные данные',
				url:			'#/home/sellers/' + $scope.seller.id + '/details'
			}, {
				id: 			1,
				title:			'Товары',
				url:			'#/home/sellers/' + $scope.seller.id + '/products'
			}, {
				id: 			2,
				title:			'Поступления',
				url:			'#/home/sellers/' + $scope.seller.id + '/specifications',
				rightButton:	{
					title:	'Новое поступление',
					action:	function() { $scope.editSpecificationPopup(); }
				}
			}, {
				id: 			3,
				title:			'Возвраты',
				url:			'#/home/sellers/' + $scope.seller.id + '/returnings',
				rightButton:	{
					title:	'Оформить возврат',
					action:	function() { $scope.editReturningPopup(); }
				}
			}, {
				id: 			4,
				title:			'Продажи',
				url:			'#/home/sellers/' + $scope.seller.id + '/sales',
				rightButton:	{
					title:	'Создать отчет',
					action:	function() { $scope.createReportPopup(); }
				}
			}, {
				id: 			5,
				title:			'Уценки',
				url:			'#/home/sellers/' + $scope.seller.id + '/depreciations'
			}, {
				id: 			6,
				title:			'Отчеты',
				url:			'#/home/sellers/' + $scope.seller.id + '/reports'
			}];
		} else {
			$scope.tabs = [{
				id: 			0,
				title:			'Основные данные',
				url:			'#/home/sellers/-1/new'
			}];
		}
		
		function getLastPath(url) {
			var path = url.split('\/');
			if (!path.length) return;
			
			return path[path.length - 1];
		}
		
		var path = getLastPath($location.$$path);
		$scope.setCurrentTab(_.find($scope.tabs, function(tab) {
			return getLastPath(tab.url) == path;
		}));
	}

})();


//	$scope.getDateStr = function(date) {
//		return new Date(date).toLocaleString();
//	};