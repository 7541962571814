(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('ProductListCtrl', ProductListCtrl)
        .controller('PendingProductListCtrl', PendingProductListCtrl)
        .controller('AddProductCtrl', AddProductCtrl)
        .controller('LabelCtrl', LabelCtrl);
    
    
	function PendingProductListCtrl($scope, $location, data, $http, _env) {
		function getPendingProducts() {
            $http.get(_env.apiUrl + '/moderatorservice/products/pending_items?skip=0&max=1000').then(function(response) {
                $scope.pendingProducts = response.data.products;
            }, function(response) {});
        }
        getPendingProducts();
		
		$scope.accounting = accounting;
	}
	
    function ProductListCtrl($scope, $location, data, $http, _env) {
        
        $scope.resetFilters = function() {
            $scope.filter = {
                statuses: [
                    { title: 'В наличий', value: "published", checked: true },
                    { title: 'Продан', value: "sold", checked: false },
                    { title: 'Удален', value: "deleted", checked: false }
                ],
                conditions: [
                    { title: 'Новый', value: "not_used", checked: true },
                    { title: 'Как новый', value: "like_new", checked: false },
                    { title: 'Хороший', value: "gently_used", checked: false }
                ],
                price: {
                    from: null,
                    to: null
                },
                seller: null,
                category: null
            };
            
            getProducts();
        }
        
        $scope.categories = data.categories;
        $http
            .get(_env.apiUrl + '/moderatorservice/sellers/list?ski=0&max=1000000')
            .then(
            function(response) {
                $scope.sellers = response.data.sellers;
            },
            function(response){
                
            });
        
        function findLastCategory(category) {
            if (!category) return null;
            if (!category.selectedCategory) return category;
            
            return findLastCategory(category.selectedCategory);
        }
        
        function prepareFiltersToSend() {
            var query = '';
            
            var filter = $scope.filter;
            if (!filter) return query;
            
            var category = findLastCategory($scope.filter.category);
            if (category) query = query + "&categoryId=" + category.id;
            
            var statuses = _.map(_.filter(filter.statuses, function(status) {return status.checked; }), function(status) {return status.value; }).join(',');
            if (statuses) query = query + "&statuses=" + statuses;
            
            var conditions = _.map(_.filter(filter.conditions, function(condition) {return condition.checked; }), function(condition) {return condition.value; }).join(',');
            if (conditions) query = query + "&conditions=" + conditions;

            if (filter.price.from) query = query + "&priceFrom=" + filter.price.from;
            if (filter.price.to) query = query + "&priceTo=" + filter.price.to;
            
            if (filter.searchKey) query = query + "&searchKey=" + filter.searchKey;
            
            if (filter.seller) query = query + "&sellerId=" + filter.seller.id;
            
            return query;
        }
        
        function getProducts() {
            $scope.loading = true;
            data.productsPageData.filter = $scope.filter;
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            $http
                .get(_env.apiUrl + '/moderatorservice/products/list?skip=' + skip + '&max=' + $scope.pager.pageSize + prepareFiltersToSend())
                .then(function(response){
                    $scope.loading = false;
                    $scope.products = response.data.products;
                   $scope.pager.total = response.data.totalCount;
                }, function(response){
                    $scope.loading = false;
                });
        }
                
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 60;
        $scope.pager.getData = getProducts;
        $scope.getProducts = getProducts;
        
        if (data.productsPageData.filter) {
            $scope.filter = data.productsPageData.filter;
            $scope.getProducts();
        } else {
            $scope.resetFilters();
        }
        
        $scope.viewProductDetails = function($event, product) {
            switch ($event.which) {
                case 1:
                    $location.path("/home/edit-product/" + product.id);
                    break;
                case 2:
                    window.open("#/home/edit-product/" + product.id, '_blank');
                    break;
                default:
                    alert('You have a strange Mouse!');
            }
        };
        
        $scope.deleteProduct = function (index) {
            var product = $scope.products[index];
            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/products/delete?id=' + product.id).then(function(response){
                    $scope.products.splice(index, 1);
                }, function(response){})
            }
        };
        
        $scope.inStockItemsCount = function(product) {
            return _.reduce(_.map(product.sizes, function(item){ return item.count;}), function(memo, num){ return memo + num; }, 0);
        }
        
        $scope.soldItemsCount = function(product) {
            return _.reduce(_.map(product.sizes, function(item){ return item.soldCount;}), function(memo, num){ return memo + num; }, 0);
        }
        
        $scope.accounting = accounting;
    }

    function AddProductCtrl($scope, $location, $http, $stateParams, _env, data) {
        
        $scope.isNewProduct = true;
        
        $scope.product = {
            status: "published",
            categories: [],
            seller: {},
            sizes: [{
                value: null,
                count: null,
                soldCount: null
            }]
        }
        
        var pId;
        if ($stateParams.id.indexOf("?") > 0) {
            pId = $stateParams.id.substr(0, $stateParams.id.indexOf('?'))
        } else {
            pId = $stateParams.id;
        }
        
        
        var pairs = [];
        function reduce(category) {
            pairs[category.id] = category;

            if (category.children && category.children.length > 0) {
                for(var i=0; i < category.children.length; i++) {
                    reduce(category.children[i]);
                }

                return;
            }

            return;
        }
        
        function flattenCategory(category) {
            if (!category) return null;
            
            if (category.selectedCategory) {
                return flattenCategory(category.selectedCategory);
            }

            return category.id;
        }
        
		_.forEach(data.categories, function(category) {
            reduce(category);
        });
        
        function bindParent(category) {
            if (category instanceof Object) {
                var parent = pairs[category.parentId];
                if (parent) {
                    parent.selectedCategory = category;
                    return bindParent(parent);
                }
            } else {
                var id = category;
                category = pairs[id];
                if (category) {
                    return bindParent(category);
                }
            }

            return category;
        }
        
        if (pId) {
            $scope.isNewProduct = false;
            $scope.product.id = parseInt(pId);
            
            if ($location.$$search.status == 'pending') {
                $http.get(_env.apiUrl + '/moderatorservice/products/pending/' + $scope.product.id).then(function(response) {
                    $scope.product.id = response.data.id;
                    $scope.product.title = response.data.title;
                    $scope.product.price = response.data.price;
                    $scope.product.initialPrice = response.data.initialPrice;
                    $scope.product.sellerId = response.data.seller.id;
					$scope.item = {
						'categories': data.categories,
						'category': null
					}
					$scope.items = [];

                    
                    getSpecificationData($scope.product.id);
                    
                }, function(response) {});
            } else {
                $http
                    .get(_env.apiUrl + '/moderatorservice/products/id/' + $scope.product.id)
                    .then(function(response) {
                        $scope.product = response.data;
                        
                        $scope.item = {
                            'categories': data.categories,
                            'category': bindParent($scope.product.categoryId)
                        }
						$scope.items = _.map($scope.product.categories, function(categoryId) {
							var categories = angular.copy(data.categories);
							return {
								categories: categories,
								category: bindParent(categoryId)
							}
						});
                    
                        if ($scope.product.condition == "not_used") {
                            $scope.product.notUsed = true;
                        }

                        $scope.seasons = _.map($scope.seasons, function(season) {
                            season.selected = $scope.product.seasons && $scope.product.seasons.indexOf(season.key) > -1
                            return season;
                        });

                        $scope.getParameters();
                        $scope.getSizes();
                    
                        getSpecificationData($scope.product.id);

                    }, function(response){});
            }
            
        }
		
        $scope.deleteCategory = function(index) {
            $scope.items.splice(index, 1);
        }

        $scope.addCategory = function() {
			var categories = angular.copy(data.categories);
            $scope.items.push({
                categories: categories,
				category: null
            });
        }
        
        function getSpecificationData(id) {
            $http
                .get(_env.apiUrl + '/moderatorservice/products/count_by_specification/' + id)
                .then(function(response){
                    $scope.product.countBySpecification = response.data.count;
                    $scope.product.unit = response.data.unit;
                });
        }
        
        $scope.categoryChanged = function() {
            setTimeout(function() {
                $scope.product.categoryId = flattenCategory($scope.item.category);
                $scope.getParameters();
                $scope.getSizes();
            }, 100);
        }
        
        $scope.getParameters = function() {
            
            $scope.parameters = [];
            
            if (!$scope.product.categoryId) {
                return;
            }

            var productIdCond = '';
            if ($scope.product.id) {
                productIdCond = '?productId=' + $scope.product.id;
            }

            $http
                .get(_env.apiUrl + '/parameterservice/categories/'+$scope.product.categoryId + productIdCond)
                .then(function(response) {
                    prepareParameters(response.data);
                }, function(response) {});
        }
        
        function prepareParameters(parametersByCategory) {

            function getProductParameterValue(parameterId, valueId) {
                var result;
                if ($scope.product.parameters) {
                    for(var i = 0; i < $scope.product.parameters.length; i++ ) {
                        var pParameter = $scope.product.parameters[i];
                        if (pParameter.id == parameterId && (!valueId || pParameter.valueId == valueId)) {
                            result = pParameter;
                            break;
                        }
                    }
                }

                return result;
            }

            $scope.parameters = _.map(parametersByCategory, function(parameter) {
                if (parameter.values.length == 0) {
                    var pParameter = getProductParameterValue(parameter.id);
                    if (pParameter) {
                        parameter.selected = pParameter.valueId;
                        parameter.customValue = pParameter.customValue;
                    }
                } else {
                    parameter.values = _.map(parameter.values, function(value) {
                        var pParameterValue = getProductParameterValue(parameter.id, value.id);
                        if (pParameterValue) {
                            value.selected = true;
                            value.customValue = pParameterValue.customValue;
                        }

                        return value;
                    });
                }

                return parameter;
            });
        }
        
        $scope.getParameterSummary = function(parameter) {
            var summary = _.map(_.filter(parameter.values, function(value) { return value.selected; }), function(value) {
                var result = '';
                
                if (value.selected) {
                    result = value.value;
                    if (parameter.hasCustomValue && value.customValue) {
                        result += ' ' + value.customValue;
                    }
                }

                return result;
            }).join(',');
            
            if (summary) {
                return summary;
            }
            
            return '---Не указан---';
        }
        
        $scope.changeNonMultipleChoiceValue = function(parameter, value) {
            _.forEach(parameter.values, function(value) {
                value.selected = false;
            });
            value.selected = true;
        }
        
        $scope.unselectAllValues = function(parameter) {
            _.forEach(parameter.values, function(value) {
                value.selected = false;
                if (parameter.hasCustomValue)
                    value.customValue = '';
            });
        }

        $scope.getSizes = function() {
            
            $scope.sizes = [];
            
            if (!$scope.product.categoryId) {
                return;
            }

            $http
                .get(_env.apiUrl + '/moderatorservice/sizes?categoryId='+$scope.product.categoryId)
                .then(function(response) {
                    $scope.sizes = response.data;
                }, function(response) {});
        }
        
        $scope.saveProduct = function () {
            var product = $scope.product;
            
            //cat
            if (!product.categoryId) {
                alert("Product category is required!");
                return;
            }
            
            //title
            if (!product.title) {
                alert('Product title is required!');            	
                return;
            }
            
            if ($scope.items) {
                product.categories = _.map($scope.items, function(item) {
                    return flattenCategory(item.category);
                });
            }
            
            //brand
            if (!product.brand) {
                alert('Product brand is required!');
                return;
            } else {
                product.brandId = product.brand.id;
            }
            
            product.condition = 'not_used';
            
            //initPrice
            if (!product.initialPrice || product.initialPrice == 0) {
                alert('Product initial price is required!');            	
                return;
            }
            product.initialPrice = parseInt($scope.product.initialPrice);
            
            //price
            if (!product.price || product.price == 0) {
                alert('Product price is required!');            	
                return;
            }
            product.price = parseInt($scope.product.price);
            
            //sale
            product.sale = parseInt($scope.product.sale);
            
            //sizes
            var countBySizes = _.reduce(
                _.map(product.sizes, function(size) { return size.soldCount + size.count; }),
                function(memo, num) { return memo + num; },
                0
            );
            if (countBySizes > $scope.product.countBySpecification) {
                alert('Вы указали количество товаров больше чем надо, всего должно быть (вместо с проданнами товарами) ' + $scope.product.countBySpecification + ' ' + $scope.product.unit);
                return;
            } else if (countBySizes < $scope.product.countBySpecification) {
                alert('Вам необходимо добавить ещё ' + ($scope.product.countBySpecification - countBySizes) + ' ' + $scope.product.unit);
                return;
            }
            
            if(product.sizes.length > 0) {
                _.forEach(product.sizes, function(size) {
                    if (!size.value) {
                        size.value = size.title ? size.title : ""
                    }
                });
            }
            
            if ($scope.sizes.length > 0 && product.sizes.length > 0) {
                _.forEach(product.sizes, function(pSize) {
                    var size = _.find($scope.sizes, function(s) { return s.id == pSize.sizeId; });
                    if (size) {
                        pSize.value = size.title;
                    }
                });
            }
            
            if ($scope.sizes.length > 0 && product.sizes.length > 0 && product.parameters && product.parameters.length > 0 && (!product.parameterSize || !product.parameterSize.id)) {
                product.parameterSize = product.sizes[0];
            }
            
            if (product.parameterSize) {
                _.forEach($scope.sizes, function(size) {
                    if (size.id == product.parameterSize.id) {
                        product.parameterSize = size;
                    }
                });
            }
            
            //desc
            if (!product.description) product.description = "";
            
            //seller
            if (!product.seller) {
                alert('Product seller is required!');
                return;
            } else {
                product.sellerId = product.seller.id;
            }
            
            //hanger
            if (!product.hanger) {
                alert('Product hanger is required!');            	
                return;
            }
                        
            //image
            if (!product.image) {
                alert('Product image is required!');            	
                return;
            }
            
            if (product.images) {
                var imgs = []; 
                for (var i = 0; i < product.images.length; i++) {
                    if (product.images[i].image) {
                        imgs.push(product.images[i]);
                    }
                }
                product.images = imgs;
            }
            
            if ($scope.selectedTags && $scope.selectedTags.length > 0) {
                product.tags = _.map($scope.selectedTags, function(tag){ return tag.id; });
            }

            //prepare parameters
            product.parameters = [];
            _.forEach($scope.parameters, function(parameter) {
                if (parameter.values && parameter.values.length > 0) {
                    _.forEach(parameter.values, function(value) {
                        if (value.selected) {
                            var parameterValue = {
                                'parameterId': parameter.id,
                                'valueId': value.id
                            }
                            if (parameter.hasCustomValue && value.customValue) {
                                parameterValue.customValue = value.customValue;
                            }
                            product.parameters.push(parameterValue);
                        }
                    });   
                } else if (parameter.hasCustomValue && parameter.customValue) {
                    product.parameters.push({
                        'parameterId': parameter.id,
                        'customValue': parameter.customValue
                    });
                }
            });

            $scope.loading = true;
            $http.put(_env.apiUrl + '/moderatorservice/products/update/' + $scope.product.id, product).then(function(response){
                if (response.data.id) {
                    $location.path('/home/label/' + response.data.id);
                } else {
                    $location.path('/home/products-list');
                }
            
                $scope.loading = false;
            }, function(response){
                $scope.loading = false;
            })
        }
        
        $scope.deleteProduct = function () {
            var r = confirm("Удалить запись?");
            if (r == true) {
                $scope.loading = true;
                $http.delete(_env.apiUrl + '/moderatorservice/products/delete?id=' + $scope.product.id).then(function(response){
                    $scope.loading = true;
                    $location.path('/products-list/');
                }, function(response){
                    $scope.loading = true;
                })
            }
        };

        var image = $($('#image-file')[0]);		
        image.bind('change', function(){
            var file = $('#image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=product_main', c, config).then(function(response){
                        $scope.product.image = response.data;
                        $scope.product.image.index = 0;
                    }, function(response){
                            alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });

        $scope.addImage = function() {
            if (!$scope.product.images) $scope.product.images = [];
            var addImg = {};
            addImg.index = $scope.product.images.length;
            $scope.product.images.push(addImg);
            setTimeout(function(){
                prepareFile(addImg.index);
            }, 1000);
        }

        $scope.removeImage = function(imageIndex) {
            $scope.product.images.splice(imageIndex, 1);
        }
        
        function prepareFile(imageId) {
            var image = $($('#image-file-' + imageId)[0]);
            image.bind('change', function(){
                var file = $('#image-file-' + imageId)[0].files[0];
                    var fr = new FileReader();
                    fr.onload = function(readerEvt) {
                        var c = readerEvt.target.result;
                        var fd = new FormData();
                        fd.append('file', c);
                        var config = {
                            transformRequest: angular.identity,
                            headers: {'Content-Type': file.type}
                        };
                        $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=product_additional', c, config).then(function(response){
                                var imageJson = response.data;
                                imageJson.index = imageId;
                                $scope.product.images[imageId] = imageJson;
                        }, function(response){	
                            alert(response.data);
                        });
                    };					
                    fr.readAsArrayBuffer(file);
            });
        }
        
        //tags
        $scope.tags = [];
        $scope.selectedTags = [];

        function initTagsAutocomplete() {
            $("#tags").autocomplete({
                source: _.map($scope.tags, function(tag){ return tag.name; }),
                select: function( event, ui ) {
                    $scope.$apply(function() {});
                }
            });            
        }
        
        $http.get(_env.apiUrl + '/moderatorservice/tags?productId=' + ($scope.product.id ? $scope.product.id : 0)).then(function(response) {
            $scope.tags = response.data,
            $scope.selectedTags = _.filter($scope.tags, function(tag){ return tag.selected; });

            initTagsAutocomplete();
        }, function(response){});

        $scope.addTag = function() {
            var tag = { 'name' : $('#tags').val() };
            if (!tag.name) return;
            if (_.find($scope.selectedTags, function(t) { return t.name == tag.name; })) return;

            var existsTag = _.find($scope.tags, function(t) { return t.name == tag.name; });
            if (existsTag) {
                $scope.selectedTags.push(existsTag);
                $('#tags').val('');
            } else {
                $http
                    .post(_env.apiUrl + '/moderatorservice/tags', tag)
                    .then(function(response){
                        tag.id = response.data.id;
                        $scope.tags.push(tag);
                        $scope.selectedTags.push(tag);
                        $('#tags').val('');
                        initTagsAutocomplete();
                    }, function(response){});
            }
        }

        $scope.removeTag = function(tag) {
            $scope.selectedTags = _.filter($scope.selectedTags, function(t) { return t.id != tag.id; });
        }
        
        $scope.sale = function () {
            var sale = Math.round(100 - 100 * ($scope.product.sale ? $scope.product.sale : $scope.product.price) / $scope.product.price);
            return sale ? sale : "0";
        }
        
        $scope.seasons = [{
                key:'spring', title: 'Весна', selected: false
            }, {
                key: 'summer', title: 'Лето', selected: false
            }, {
                key: 'autumn', title: 'Осень', selected: false
            }, {
                key: 'winter', title: 'Зима', selected: false
            }];
        
        $scope.toggleSeason = function() {
            if (!$scope.product.seasons) $scope.product.seasons = [];
            $scope.product.seasons = _.map(
                _.filter($scope.seasons,
                         function(season) {
                            return season.selected;
                         }),
                function(season) {
                    return season.key;
                });
            
            $scope.allSeasonsSelected = $scope.product.seasons.length == $scope.seasons.length;
        };
        
        $scope.toggleAllSeason = function() {
            $scope.allSeasonsSelected = !($scope.allSeasonsSelected === null || $scope.allSeasonsSelected == false);
            $scope.product.seasons = _.map($scope.seasons, function(season) {
                season.selected = $scope.allSeasonsSelected;
                return season.key;
            });
            
            $scope.toggleSeason();

        };
        
        $scope.addSize = function() {
            $scope.product.sizes.push({
                value: null,
                count: null,
                soldCount: null
            });
        }
        
        $scope.removeSize = function(atIndex) {
            $scope.product.sizes.splice(atIndex, 1);
        }
        
        
        // brands
        $scope.brands = [];
        $http
            .get(_env.apiUrl + '/moderatorservice/brands/list')
            .then(function(response) {
                $scope.brands = response.data;
                if ($scope.product.brandId) {
                    $scope.product.brand = _.find($scope.brands, function(brand) { return brand.id == $scope.product.brandId; });
                }
            }, function(response){});
        
        // sellers
        $http
            .get(_env.apiUrl + '/moderatorservice/sellers/list?ski=0&max=1000000')
            .then(
            function(response) {
                $scope.sellers = response.data.sellers;
                if ($scope.product.sellerId) {
                    $scope.product.seller = _.find($scope.sellers, function(seller) { return seller.id == $scope.product.sellerId; });
                }
            },
            function(response){
                
            });
        
    }

    function LabelCtrl($scope, $location, $http, $stateParams, _env) {
                
        $scope.label = {};
        
        if ($stateParams.id) {
            $http
                .get(_env.apiUrl + '/moderatorservice/products/id/' + $stateParams.id)
                .then(function(response){
                    $scope.label.price = response.data.price;
                    $scope.label.title = response.data.title;
                    $scope.label.size = response.data.sizes[0].value;
                    $scope.label.color = response.data.color;
                    $scope.label.materials = response.data.materials;
                    $scope.label.id = response.data.id;
                
                    if (response.data.sale && response.data.sale > 0)
                        $scope.label.price = response.data.sale;
                
                    $scope.product = response.data;                
                }, function(response){});
        }
        
        $scope.previewMode = false;
        
        $scope.previewLabel = function() {
            if (!$scope.label.id) {
                getBarcode(function(barcodeNumber) {
                    $scope.label.id = barcodeNumber;
                    drawBarcode(barcodeNumber);
                    $scope.previewMode = true;
                });
            } else {
                    drawBarcode($scope.label.id);
                    $scope.previewMode = true;
            }
        }
        
        $scope.printLabel = function() {
            var mywindow = window.open('', '', '');
            mywindow.document.write('<html><head>');
            mywindow.document.write('</head><body >');
            mywindow.document.write($('#previewTable').html());
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10

            mywindow.print();
            mywindow.close();

            return true;
        }
        
        function getBarcode(callback) {
            $http.post(_env.apiUrl + '/moderatorservice/barcodes/add', {}).then(function(response){
                callback(response.data.id);
            }, function(response){
            });
        }
        
        function drawBarcode(barcodeNumber) {		
            var settings = {
                barWidth: 2,
                barHeight: 50,
                showHRI: false
            };    
            $("#1D-barcode").barcode(barcodeNumber.toString(), "code93", settings);
        }
        
        $scope.numberWithSpaces = function(x) {
            if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");            
            return null;
        }

        
    }
    
})();