(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .directive('selectParameter', selectParameter);

        function selectParameter(_env, $http, $compile, $parse, $interval) {
            return {
                restrict: 'EA',
                scope: {
                    items: "=",
                    callback: "="
                },
                templateUrl: 'partials/product-parameter/select.html',
                link: function(scope, element, attrs) {

                    function getGroupedParameters(callback) {
                        $http.get(_env.apiUrl + '/parameterservice/grouped').then(function(response){
                            scope.groups = response.data;
                            if(callback) callback();
                        }, function(response) {});
                    }

                    getGroupedParameters(function() {

                        var selectedParameterIds = _.map(scope.items, function(item) { return item.id; });

                        scope.groups = _.map(scope.groups, function(group) {

                            group.parameters = _.map(group.parameters, function(parameter) {

                                parameter.selected = _.contains(selectedParameterIds, parameter.id);

                                return parameter;

                            });

                            checkGroupToSelectAll(group);

                            var hasSelectedItems = _.find(
                                group.parameters,
                                function(parameter) { return parameter.selected; }
                            ) != null;

                            group.collapsed = hasSelectedItems;


                            return group;
                        });

                    });

                    scope.onGroupChange = function(group) {

                        group.parameters = _.map(group.parameters, function(parameter) {
                            parameter.selected = group.selected;
                            return parameter;
                        });

                    }

                    scope.onParameterChange = function(parameter, group) {

                        checkGroupToSelectAll(group);

                    }

                    function checkGroupToSelectAll(group) {

                        var hasUnselectedItems = _.find(
                            group.parameters,
                            function(parameter) { return !parameter.selected; }
                        ) != null;

                        group.selected = !hasUnselectedItems;

                    }

                    scope.done = function() {

                        var parameters = [];

                        _.forEach(scope.groups, function(group) {

                            _.forEach(group.parameters, function(parameter) {

                                if (parameter.selected && _.find(parameters, function(p) { return p.id == parameter.id}) == null) {
                                    parameters.push(parameter);
                                }

                            });

                        });

                        scope.callback(parameters);

                    }
                }
            }
        }

})();