(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('SellerProductsCtrl', SellerProductsCtrl)
        .controller('PendingProductPopupCtrl', PendingProductPopupCtrl);

    function SellerProductsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

        function getData(callback) {
            $http
                .get(_env.apiUrl + '/moderatorservice/products/products_for_finance_manager?max=10000000&sellerId=' + $stateParams.id)
                .then(function(response) {
                    $scope.products = response.data.products;
                }, function(response){});
        }

        getData();

        $scope.getDateStr = function(date) {
        	return new Date(date).toLocaleString();
        };

        $scope.editProductPopup = function(product) {
            if (!product) {
                product = {
                    sellerId: $scope.seller.id
                };
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/product/pending-product.html',
                controller: 'PendingProductPopupCtrl',
                resolve: {
                    product: function() {
                        return product;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.depreciationPopup = function(product) {
            if (!product) {
                product = {
                    sellerId: $scope.seller.id
                };
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/depreciations/new.html',
                controller: 'DepreciationPopupCtrl',
                resolve: {
                    product: function() {
                        return product;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.removeProduct = function(product) {
            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/products/delete-pending-item?id=' + product.id).then(function(response){
                    getData();
                }, function(response){});
            }

        }

        $scope.accounting = accounting;
        $scope.moment = moment;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}
    }

    function PendingProductPopupCtrl($scope, $http, $uibModalInstance, product, callback, _env) {
        $scope.product = product;
        $scope.product.comission = $scope.product.price - $scope.product.initialPrice;

        $scope.save = function () {
            if (!$scope.product.title) {
                alert('Укажите название');
                return;
            }

            $scope.product.initialPrice = $scope.product.price - $scope.product.comission;

            if ($scope.product && $scope.product.seller) {
                $scope.product.sellerId = $scope.product.seller.id;
            }

            if ($scope.product && !$scope.product.status) {
                $scope.product.status = 'pending';
            }

            $http
                .put(_env.apiUrl + '/moderatorservice/products/update_pending_item', $scope.product)
                .then(function(response) {
                    callback();
                    $uibModalInstance.dismiss('cancel');
                }, function(response){});
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();