(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('SellerReturningsCtrl', SellerReturningsCtrl)
        .controller('ReturningProductPopupCtrl', ReturningProductPopupCtrl)
        .controller('ReturningCtrl', ReturningCtrl);

	function SellerReturningsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

	    $scope.seller = {};
	    $scope.seller.id = $stateParams.id;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}

        $scope.accounting = accounting;
        $scope.moment = moment;

        function getReturnings(callback) {
            $http
                .get(_env.apiUrl + '/moderatorservice/returnings?sellerId=' + $scope.seller.id)
                .then(function(response) {
                    $scope.returnings = response.data;
                    if (callback) callback();
                }, function(response) {});
        }

        getReturnings();

        $scope.editReturningPopup = function(returning) {
			if (!returning) returning = {
				products: [],
				sellerId: parseInt($scope.seller.id)
			};
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/returning/new.html',
                controller: 'ReturningProductPopupCtrl',
                resolve: {
                    returning: function() {
                        return returning;
                    },
                    callback: function() {
                        return getReturnings;
                    }
                }
            });
        }
		
		$scope.$parent.editReturningPopup = $scope.editReturningPopup;

        $scope.getReturningTotal = function(returning) {
            var totalCount = 0, totalPrice = 0;

            _.forEach(returning.products, function(product) {
                totalCount += product.quantity;
            });

            _.forEach(returning.products, function(product) {
                totalPrice += product.quantity * product.price;
            });

            return {
                'quantity': totalCount,
                'price': totalPrice
            };
        }

        $scope.removeReturning = function(returning) {
            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/returnings/' + returning.id).then(function(response){
                    $scope.returnings = _.filter($scope.returnings, function(ret) {
                        return returning.id != ret.id;
                    });
                }, function(response){});
            }
        }

	}
    
    
    function ReturningProductPopupCtrl($scope, $http, $uibModalInstance, returning, callback, _env) {
        $scope.returning = returning;
		
		
    
        function getProducts(callback) {
            $http
                .get(_env.apiUrl + '/moderatorservice/products/products_for_finance_manager?max=1000000&sellerId=' + $scope.returning.sellerId)
                .then(function(response) {
                    callback(response.data.products);
                }, function(response) {});
        }
		
		getProducts(function(products) {
			$scope.products = _.map(products, function(product){
				var sProduct = _.find(returning.products, function(sProduct) { return product.id == sProduct.productId; });
				product.selected = sProduct !== undefined;
				product.quantity = sProduct ? sProduct.count : undefined;

				return product;
			});
		});

        $scope.save = function () {
            $scope.returning.products = _.map(_.filter(
                $scope.products,
                function(product) {
                    return product.selected;
                }), function(product) {
                    return {
                        productId: product.id,
                        quantity: product.quantity,
						price: product.price,
						initialPrice: product.initialPrice,
						title: product.title,
						unit: product.unit
                    };
                });
            $http
                .post(_env.apiUrl + '/moderatorservice/returnings', $scope.returning)
                .then(function(response) {
                    callback();
                    $uibModalInstance.dismiss('cancel');
                }, function(response){});
        };
        
        $scope.getProduct = function(id) {
            return _.find($scope.products, function(product) { return product.id == id; });
        }
        
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function ReturningCtrl($scope, $location, $stateParams, $http, _env) {
		
		$http.get(_env.apiUrl + '/moderatorservice/sellers/' + $stateParams.sellerId)
			.then(function(response) {
				$scope.seller = response.data;
			
				$http.get(_env.apiUrl + '/moderatorservice/returnings/' + $stateParams.returningId)
					.then(function(response) {
						$scope.returning = response.data;
						$scope.total = {
							quantity: _.reduce(_.map($scope.returning.products, function(product) { return product.quantity; }), function(memo, num){ return memo + num; }, 0),
							price: _.reduce(_.map($scope.returning.products, function(product) { return product.quantity * product.price; }), function(memo, num){ return memo + num; }, 0)
						}
					});
			}, function(response) {});
			
        $scope.accounting = accounting;
        $scope.moment = moment;
	}
    
})();