(function () {
        'use strict';

        angular
            .module('bulavka-console')
            .controller('SellerDetailsCtrl', SellerDetailsCtrl)
            .controller('ResetPasswordPopupCtrl', ResetPasswordPopupCtrl)
            .controller('ContractCtrl', ContractCtrl);

	function SellerDetailsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

		$scope.seller = {};
		if ($stateParams.id == -1) {
			$stateParams.id = null;
		} else {
			$scope.seller.id = $stateParams.id;
		}

		function getData(callback) {
			$http
				.get(_env.apiUrl + '/moderatorservice/sellers/' + $scope.seller.id)
				.then(function(response) {
					$scope.seller = response.data;
				}, function(response) {});
		}

		if ($scope.seller.id) {
		    getData();
		}

        $scope.getDateStr = function(date) {
        	return new Date(date).toLocaleString();
        };

        var phoneRegex = /^\+998\d{9}$/;
        function validatePhone(phone) {
            return phone.match(phoneRegex);
        }

        var emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        function validateEmail(email) {
            return email.match(emailRegex);
        }

        $scope.save = function() {
            var seller = {};

            seller.id = $scope.seller.id;

            if (!$scope.seller.type) {
                alert('Выберите вид деятельности');
                return;
            }
            seller.type = $scope.seller.type;

            if (!$scope.seller.contractNumber || !$scope.seller.contractNumber.trim()) {
                alert('Укажите номер договора');
                return;
            }
            seller.contractNumber = $scope.seller.contractNumber.trim();

            if ($scope.seller.type == 'legal_entity') {
                if (!$scope.seller.companyName || !$scope.seller.companyName.trim()) {
                    alert('Укажите название организации');
                    return;
                }
                seller.companyName = $scope.seller.companyName.trim();
            }

            if (!$scope.seller.firstName || !$scope.seller.firstName.trim()) {
                alert('Укажите имя');
                return;
            }
            seller.firstName = $scope.seller.firstName.trim();

            if (!$scope.seller.lastName || !$scope.seller.lastName.trim()) {
                alert('Укажите фамилию');
                return;
            }
            seller.lastName = $scope.seller.lastName.trim();

            if (!$scope.seller.middleName || !$scope.seller.middleName.trim()) {
                alert('Укажите отчество');
                return;
            }
            seller.middleName = $scope.seller.middleName.trim();

            if ($scope.seller.type != 'legal_entity') {
                if (!$scope.seller.taxpayerId || !$scope.seller.taxpayerId.trim()) {
                    alert('Укажите ИНН');
                    return;
                }
                seller.taxpayerId = $scope.seller.taxpayerId.trim();
            } else {
                seller.taxpayerId = undefined;
            }

            if (!$scope.seller.address || !$scope.seller.address.trim()) {
                alert('Укажите адрес');
                return;
            }
            seller.address = $scope.seller.address.trim();

            if (!$scope.seller.phone || !validatePhone($scope.seller.phone)) {
                alert('Укажите телефон в формате +998xxxxxxxxx');
                return;
            }
            seller.phone = $scope.seller.phone.trim();

            if ($scope.seller.additionalPhone) {
                if (!validatePhone($scope.seller.additionalPhone)) {
                    alert('Укажите доп. телефон в формате +998xxxxxxxxx');
                    return;
                }
                seller.additionalPhone = $scope.seller.additionalPhone.trim();
            }


            if ($scope.seller.type == 'individual') {
                if (!$scope.seller.passportSeries || !$scope.seller.passportSeries.trim()) {
                    alert('Укажите серия паспорта');
                    return;
                }
                seller.passportSeries = $scope.seller.passportSeries.trim();

                 if (!$scope.seller.passportNumber || !$scope.seller.passportNumber.trim()) {
                    alert('Укажите номер паспорта');
                    return;
                }
                seller.passportNumber = $scope.seller.passportNumber.trim();

                 if (!$scope.seller.passportCreatedBy || !$scope.seller.passportCreatedBy.trim()) {
                    alert('Укажите, кем выдан паспорт');
                    return;
                }
                seller.passportCreatedBy = $scope.seller.passportCreatedBy.trim();

                 if (!$scope.seller.passportCreatedAt || !$scope.seller.passportCreatedAt.trim()) {
                    alert('Укажите, когда выдан паспорт ');
                    return;
                }
                seller.passportCreatedAt = $scope.seller.passportCreatedAt.trim();
            }

            if (!$scope.seller.bank || !$scope.seller.bank.trim()) {
                alert('Укажите банк');
                return;
            }
            seller.bank = $scope.seller.bank.trim();

            if (!$scope.seller.bankId || !$scope.seller.bankId.trim()) {
                alert('Укажите МФО банка');
                return;
            }
            seller.bankId = $scope.seller.bankId.trim();

            if ($scope.seller.type == 'legal_entity') {
                if (!$scope.seller.oked || !$scope.seller.oked.trim()) {
					$scope.seller.oked = '';
                }
                seller.oked = $scope.seller.oked.trim();
            }

            if (!$scope.seller.account || !$scope.seller.account.trim()) {
                alert('Укажите расчетный счет');
                return;
            }
            seller.account = $scope.seller.account.trim();

            if ($scope.seller.type == 'legal_entity') {
                if (!$scope.seller.bankTaxpayerId || !$scope.seller.bankTaxpayerId.trim()) {
                    alert('Укажите ИНН банка');
                    return;
                }
            }
            seller.bankTaxpayerId = $scope.seller.bankTaxpayerId ? $scope.seller.bankTaxpayerId.trim() : undefined;

            if ($scope.seller.cardNumber && $scope.seller.cardNumber.trim()) {
                seller.cardNumber = $scope.seller.cardNumber.trim();
            }

            if (seller.id) {
                $http.put(_env.apiUrl + '/moderatorservice/sellers/' + seller.id, seller).then(function(response) {
                    $location.path("/home/sellers");
                }, function(response) {});
            } else {
                $http.post(_env.apiUrl + '/moderatorservice/sellers', seller).then(function(response) {
                    alert('Комитент успешно добавлен');
                    $scope.seller.id = response.data.id;
                }, function(response) {});
            }
        }

        $scope.resetPassword = function() {
            $uibModal.open({
                templateUrl: 'partials/inventory/sellers/detail/reset-password.html',
                controller: 'ResetPasswordPopupCtrl',
                resolve: {
                    seller: function() {
                        return $scope.seller;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.accounting = accounting;
        $scope.moment = moment;
	}

	function ResetPasswordPopupCtrl($scope, $http, $uibModalInstance, seller, callback, _env) {

        $scope.seller = seller;

        $scope.save = function () {
            var data = {
                sellerId: seller.id,
                password: $scope.password
            }
            $http
                .put(_env.apiUrl + '/moderatorservice/sellers/update_password', data)
                .then(function(response) {
                    callback();
                    $uibModalInstance.dismiss('cancel');
                }, function(response) {});
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.generatePassword = function() {

            $scope.password = randomString();
        }

        function randomString() {
          var text = "";
          var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for (var i = 0; i < 7; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

          return text;
        }
    }

    function ContractCtrl($scope, $location, $stateParams, $http, _env) {
        $http.get(_env.apiUrl + '/moderatorservice/sellers/' + $stateParams.id).then(function(response) {
            $scope.seller = response.data;
            setTimeout(function () {
                window.print();
            }, 1000);
        }, function(response) {});

        $scope.accounting = accounting;
        $scope.moment = moment;
    }

})();