(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('SellerReportsCtrl', SellerReportsCtrl)
        .controller('CreateSaleReportCtrl', CreateSaleReportCtrl)
        .controller('PrintSaleReportCtrl', PrintSaleReportCtrl);


	function SellerReportsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

	    $scope.seller = {};
	    $scope.seller.id = $stateParams.id;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}

        $scope.accounting = accounting;
        $scope.moment = moment;

        function getSaleReports(sellerId) {
            $http.get(_env.apiUrl + '/moderatorservice/sale_reports?sellerId=' + $scope.seller.id).then(function(response) {
                $scope.reports = _.map(response.data, function(report) {

                    var totalPrice = sum(_.map(report.products, function(p) { return p.price * p.quantity; }));
                    var totalComission = sum(_.map(report.products, function(p) { return (p.price - p.initialPrice) * p.quantity; }));

                    report.total = totalPrice - totalComission - report.otherExpense - report.alreadyPaid + report.balanceDebts;

                    return report;
                });

            }, function(response) { });
        }

        getSaleReports();

        $scope.removeSaleReport = function(report) {
            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/sale_reports/' + report.id).then(function(response){
                    $scope.reports = _.filter($scope.reports, function(rep) {
                        return report.id != rep.id;
                    });
                }, function(response){});
            }
        }
		
		function sum(numbers) {
			return _.reduce(numbers, function(memo, num){ return memo + num; }, 0)
		}
		
		$scope.editReportPopup = function(report) {
			if (!report) report = {
				otherExpense: 0,
				balanceDebts: 0,
				alreadyPaid: 0,
				products: [],
				date: (new Date()).getTime()
			};

			report.sellerId = parseInt($scope.seller.id);

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/report/new.html',
                controller: 'CreateSaleReportCtrl',
                resolve: {
                    report: function() {
                        return report;
                    },
                    callback: function() {
                        return getSaleReports;
                    }
                }
            });
        }

        $scope.removeSaleReport = function(report) {

            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/sale_reports/' + report.id).then(function(response){
                    $scope.reports = _.filter($scope.reports, function(rep) {
                        return report.id != rep.id;
                    });
                }, function(response){});
            }
        }

        $scope.onTimeSet = function (date, specification) {
            specification.modified = date.getTime();
			$http
				.put(_env.apiUrl + '/moderatorservice/specifications/' + specification.id, { 'modified': date.getTime() })
				.then(function(response) {}, function(response){});

        }


	}
    
	function CreateSaleReportCtrl($scope, $http, $uibModalInstance, report, callback, _env) {

		$scope.report = report;
		$scope.products = _.map(report.products, function(product) {
			product.selected = true;
			return product;
		});
		
		var url = _env.apiUrl + '/moderatorservice/sale_reports/unreported_sales?sellerId=' + report.sellerId;
		$http.get(url).then(function(response) {
			$scope.products = $scope.products.concat(response.data);
		}, function(response) { });
		
		$scope.onTimeSet = function(date) {
			$scope.report.date = date.getTime();
		}
		
		$scope.moment = moment;
        $scope.accounting = accounting;
		
        $scope.save = function () {
			$scope.report.products = null;
            $scope.report.orderItemIds = _.map(
				_.filter(
					$scope.products,
					function(p) { return p.selected; }
				),
				function(fp) { return fp.orderItemId; }
			);
			
			if (report.id) {
				$http
					.put(_env.apiUrl + '/moderatorservice/sale_reports/' + report.id, $scope.report)
					.then(function(response) {
						callback();
						$uibModalInstance.dismiss('cancel');
					}, function(response){});
			} else {
				$http
					.post(_env.apiUrl + '/moderatorservice/sale_reports', $scope.report)
					.then(function(response) {
						callback();
						$uibModalInstance.dismiss('cancel');
					}, function(response){});
			}
        };
        
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
		
		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}
    }

    
    function PrintSaleReportCtrl($scope, $location, $stateParams, $http, _env) {
		
		$http.get(_env.apiUrl + '/moderatorservice/sellers/' + $stateParams.sellerId)
			.then(function(response) {
				$scope.seller = response.data;
		
				$http.get(_env.apiUrl + '/moderatorservice/sale_reports/' + $stateParams.reportId).then(function(response) {
					$scope.report = response.data;
					$scope.report.total = prepareTotal(response.data);
				}, function(response) { });

			}, function(response) {});
		
		function prepareTotal(report) {
			return {
				'price': 			sum(_.map($scope.report.products, function(p) { return p.price * p.quantity; })),
				'comission': 		sum(_.map($scope.report.products, function(p) { return (p.price - p.initialPrice) * p.quantity; }))
			}
		}
		
		function sum(numbers) {
			return _.reduce(numbers, function(memo, num){ return memo + num; }, 0)
		}
			
        $scope.accounting = accounting;
        $scope.moment = moment;
	}
    
})();