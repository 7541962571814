(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('BrandsCtrl', BrandsCtrl)
        .controller('BrandCtrl', BrandCtrl);
    
    function BrandsCtrl($scope, $location, data, $http, _env) {
        
        $scope.viewDetails = function(brand) {
        	$location.path("/home/brand-details/" + brand.id);
        };
        
        $scope.withItems = true;
        
        // search
        
        function getBrands(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.brands = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/brands/search?skip=' + skip + '&max=' + $scope.pager.pageSize;
            if ($scope.searchKey)
                url += '&searchKey=' + encodeURIComponent($scope.searchKey);
            if ($scope.withItems)
                url += '&withItems=true';
            
            $http.get(url).then(function(response){
                $scope.brands = response.data.brands;
                $scope.pager.total = response.data.totalCount;
            }, function(response){});
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 50;
        $scope.pager.getData = getBrands;
        
        $scope.getBrands = getBrands;
        
        $scope.clearSearch = function() {
            $scope.searchKey = null;
            getBrands(true);
        }
        
        $scope.getImage = function(brand) {
            if (brand.image)
                return brand.image;
            
            return 'http://media.bulavka.uz/other/placeholder';
        }
        
        $scope.getBrands();
    }

    function BrandCtrl($scope, $location, $http, $stateParams, _env) {

        $scope.isNewBrand = true;
        
        $scope.brand = {};
        
        if ($stateParams.id) {
            $scope.isNewBrand = false;
            $http
                .get(_env.apiUrl + '/moderatorservice/brands/details?id=' + $stateParams.id)
                .then(function(response){
                    $scope.brand = response.data;
                }, function(response){});
        }
        
        $scope.save = function () {
            //title
            if (!$scope.brand.title) {
                alert('Brand title is required!');            	
                return;
            }
            
            //slug
            if (!$scope.brand.slug) {
                alert('Brand slug is required!');            	
                return;
            }
            
            //h2
            if (!$scope.brand.h2) {
                alert('Brand h2 is required!');            	
                return;
            }

            $http.put(_env.apiUrl + '/moderatorservice/brands/update', $scope.brand).then(function(response){
                $location.path('/home/brands');
            }, function(response){})
        }
        
        $scope.remove = function () {
            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/brands/delete?id=' + $scope.brand.id).then(function(response){
                    $location.path('/home/brands');
                }, function(response){})
            }
        };
        
        var logo = $($('#image-file')[0]);
        logo.bind('change', function(){
            var file = $('#image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=logo', c, config).then(function(response) {
                        $scope.brand.image= response.data.image;
                    }, function(response){
                        alert(response.data);
                    });
                };
                fr.readAsArrayBuffer(file);
        });
        
        var logo = $($('#image-file-banner')[0]);
        logo.bind('change', function(){
            var file = $('#image-file-banner')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=logo', c, config).then(function(response) {
                        $scope.brand.banner= response.data.image;
                    }, function(response) {
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        $scope.getImage = function() {
            if ($scope.brand && $scope.brand.image)
                return $scope.brand.image;
            
            return 'http://media.bulavka.uz/other/placeholder';
        }
    }
})();