(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('DepreciationsCtrl', DepreciationsCtrl)
        .controller('DepreciationPopupCtrl', DepreciationPopupCtrl);

	function DepreciationsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

	    $scope.seller = {};
	    $scope.seller.id = $stateParams.id;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}

        $scope.accounting = accounting;
        $scope.moment = moment;

        function getDepreciations(callback) {
            $http
                .get(_env.apiUrl + '/moderatorservice/depreciations/' + $scope.seller.id)
                .then(function(response) {
                    $scope.depreciations = response.data;
                    if (callback) callback();
                }, function(response) {});
        }

        getDepreciations();

	}


    function DepreciationPopupCtrl($scope, $http, $uibModalInstance, product, callback, _env) {
        $scope.product = product;
        $scope.product.comission = product.price - product.initialPrice;

        $scope.save = function () {
            var data = {
                productId:      product.id,
                price:          product.price,
                initialPrice:   product.price - product.comission
            };
            $http
                .post(_env.apiUrl + '/moderatorservice/depreciations', data)
                .then(function(response) {
                    callback();
                    $uibModalInstance.dismiss('cancel');
                }, function(response){});
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();