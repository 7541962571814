(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('OrdersCtrl', OrdersCtrl)
        .controller('NewOrdersCtrl', NewOrdersCtrl)
        .controller('PaymentPendingCtrl', PaymentPendingCtrl)
        .controller('FittingRoomCtrl', FittingRoomCtrl)
        .controller('RoadwayBillCtrl', RoadwayBillCtrl)
        .controller('CompletedOrdersCtrl', CompletedOrdersCtrl)
        .controller('CanceledOrdersCtrl', CanceledOrdersCtrl)
        .controller('ProductPopupCtrl', ProductPopupCtrl)
        .controller('HistoryPopupCtrl', HistoryPopupCtrl)
        .controller('EditOrderDetailsPopupCtrl', EditOrderDetailsPopupCtrl);
    
    function OrdersCtrl($scope, $location, $uibModal, $http, _env) {
        $scope.tabs = [{
            title: 'Новые',
            path: '/home/orders/new'
        },{
            title: 'На примерке',
            path: '/home/orders/pending'
        },{
            title: 'Завершенные',
            path: '/home/orders/completed'
        },{
            title: 'Отмененные',
            path: '/home/orders/canceled'
        }];
        
        $scope.changeTab = function(tab) {
            $scope.currentTab = tab;
            $location.path(tab.path);
        }
        
        if ($location.$$path == '/home/orders') {
            $scope.changeTab($scope.tabs[0]);
        } else {
            var curTab = _.find($scope.tabs, function(tab) {
               return tab.path == $location.$$path;
            });
            if (curTab) {
                $scope.changeTab(curTab);
            }
        }
        
        $scope.openProductPopup = function (productId) {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/orders/product-popup.html',
                controller: 'ProductPopupCtrl',
                resolve: {
                    productId: function () {
                        return productId;
                    }
                }
            });
        }
        
        $scope.openHistoryPopup = function(phone) {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/orders/history-popup.html',
                controller: 'HistoryPopupCtrl',
                resolve: {
                    phone: function () {
                        return phone;
                    }
                }
            });
        }
        
        $scope.commonPlatform = function(platform) {
            if (platform == 'ios' || platform == 'android')
                return 'mobile';
            
            return 'web';
        }

        $scope.editOrderDetails = function(order, _callback) {
            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/orders/edit-details.html',
                controller: 'EditOrderDetailsPopupCtrl',
                resolve: {
                    order: function () {
                        return order;
                    },
                    callback: function () {
                        return function(editedOrder) {
                            alert('Заказ успешно сохранен.');
                            if (_callback)
                                _callback();
                        }
                    }
                }
            });
        }

    }
    
    function ProductPopupCtrl($scope, $http, $uibModalInstance, productId, _env) {
        $scope.product = {};
        $http
            .get(_env.apiUrl + '/moderatorservice/products/id/' + productId)
            .then(function(response) {
                $scope.product = response.data;            
            }, function(response){});

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
    
    function HistoryPopupCtrl($scope, $http, $uibModalInstance, phone, _env) {
        $scope.phone = phone;
        $http
            .get(_env.apiUrl + '/moderatorservice/orders/by_phone/' + phone)
            .then(function(response) {
                $scope.orders = response.data.orders;
            }, function(response){});

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
        
        $scope.accounting = accounting;
        $scope.moment = moment;
        
        $scope.statusesRuTitles = {
            'pending' : 'В ожидании',
            'reserved' : 'В ожидании',
            'on_fitting_room' : 'На примерке',
            'done' : 'Завершено',
            'canceled' : 'Отменено'
        }
        
    }

    function EditOrderDetailsPopupCtrl($scope, $http, $uibModalInstance, order, callback, _env) {

        $scope.order = angular.copy(order);
        $scope.saveOrderDetails = function(order) {
            $scope.order.deliveryDate = new Date($scope.order.deliveryDate).getTime();
            $http.put(_env.apiUrl + '/moderatorservice/orders/update_details', $scope.order).then(function(response){
                $uibModalInstance.dismiss('cancel');
                if (callback)
                    callback();
            }, function(response){});
        }

    }
    
    function PaymentPendingCtrl($scope, $location, $http, _env) {
        
        $scope.error = null;
        
        function getOrders() {
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/orders/list?status=pending&skip=' + skip + '&max=' + $scope.pager.pageSize;
            $http.get(url).then(function(response){
                $scope.orders = response.data.orders;
                $scope.pager.total = response.data.totalCount;
                $scope.error = null;
            }, function(response){
                $scope.error = response.data.msg;
            });
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 25;
        $scope.pager.getData = getOrders;
        
        getOrders();
        
        $scope.accounting = accounting;
        $scope.moment = moment;

    }
    
    function NewOrdersCtrl($scope, $location, $http, _env) {
        
        $scope.error = null;
        
        function getOrders() {
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/orders/list?status=reserved&skip=' + skip + '&max=' + $scope.pager.pageSize;
            $http.get(url).then(function(response){
                $scope.orders = response.data.orders;
                $scope.pager.total = response.data.totalCount;
                $scope.error = null;
            }, function(response){
                $scope.error = response.data.msg;
            });
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 25;
        $scope.pager.getData = getOrders;
        
        getOrders();
        
        function cancel(atIndex) {
            var data = angular.copy($scope.orders[atIndex]);
            data.deliveryDate = new Date(data.deliveryDate).getTime();
            
            data.status = 'canceled';
            data.items = [];
            $http.put(_env.apiUrl + '/moderatorservice/orders/update', data).then(function(response) {
                $scope.orders.splice(atIndex, 1);
                alert('Заказ успешно обработан.')
            }, function(response) {
                $scope.error = response.data.msg || response.data;
            });
        }
        
        function accept(atIndex) {
            var data = angular.copy($scope.orders[atIndex]);
            data.deliveryDate = new Date(data.deliveryDate).getTime();
            
            if (!data.userAddress) {
                alert('Пожалуйста, обработайте заказ полностью. Введите адрес доставки. Выберите дату доставки. Выберите товары на примерку или отмените.');
                return;
            }
            
            data.status = 'on_fitting_room';
            data.items = _.map(data.items, function(item) {
                return {
                    itemId: item.product.id,
                    isAccepted: item.isAccepted,
                    isSold: item.isSold,
                    size: item.size,
                    quantity: item.quantity
                }
            });
            $http.put(_env.apiUrl + '/moderatorservice/orders/update', data).then(function(response) {
                $scope.orders.splice(atIndex, 1);
                alert('Заказ успешно обработан.')
            }, function(response) {
                $scope.error = response.data.msg || response.data;
            });
        }
        
        $scope.complete = function(atIndex) {
            var order = angular.copy($scope.orders[atIndex]);
            var isCompleted = _.filter(order.items, function(item) { return !item.isHandled }).length == 0;
            if (!isCompleted) {
                alert('Пожалуйста, обработайте заказ полностью. Введите адрес доставки. Выберите дату доставки. Выберите товары на примерку или отмените.');
                return;
            }
            
            var isAccepted = _.filter(order.items, function(item) { return item.isAccepted }).length > 0;
            if (isAccepted)
                accept(atIndex)
            else
                cancel(atIndex)
        }
            
        $scope.accounting = accounting;
        $scope.moment = moment;
    }

    function FittingRoomCtrl($scope, $location, $http, _env) {
        
        $scope.error = null;
        
        function getOrders() {
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/orders/list?status=on_fitting_room&skip=' + skip + '&max=' + $scope.pager.pageSize;
            $http.get(url).then(function(response){
                $scope.orders = response.data.orders;
                $scope.pager.total = response.data.totalCount;
                $scope.error = null;
            }, function(response){
                $scope.error = response.data.msg;
            });
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 25;
        $scope.pager.getData = getOrders;
        
        getOrders();
        
        function cancel(atIndex) {
            var data = angular.copy($scope.orders[atIndex]);
            data.deliveryDate = new Date(data.deliveryDate).getTime();
            data.status = 'canceled';
            data.items = [];
            $http.put(_env.apiUrl + '/moderatorservice/orders/update', data).then(function(response) {
                $scope.orders.splice(atIndex, 1);
                alert('Заказ успешно обработан.');
            }, function(response) {
                $scope.error = response.data.msg || response.data;
            });            
        }
        
        function complete(atIndex) {
            var data = angular.copy($scope.orders[atIndex]);
            data.deliveryDate = new Date(data.deliveryDate).getTime();
            data.status = 'done';
            data.items = _.map(data.items, function(item) {
                return {
                    itemId: item.product.id,
                    isAccepted: item.isAccepted,
                    isSold: item.isSold,
                    size: item.size,
                    quantity: item.quantity
                }
            });
            $http.put(_env.apiUrl + '/moderatorservice/orders/update', data).then(function(response) {
                $scope.orders.splice(atIndex, 1);
                alert('Заказ успешно обработан.');
            }, function(response) {
                $scope.error = response.data.msg || response.data;
            });
        }
        
        $scope.complete = function(atIndex) {
            var order = angular.copy($scope.orders[atIndex]);
            var isCompleted = _.filter(order.items, function(item) { return !item.isHandled && item.isAccepted }).length == 0;
            if (!isCompleted) {
                alert('Пожалуйста, обработайте заказ полностью.  Верните товары на склад или отметьте продан.');
                return;
            }
            
            var isCompleted = _.filter(order.items, function(item) { return item.isSold }).length > 0;
            if (isCompleted)
                complete(atIndex)
            else
                cancel(atIndex)
        }
        
        $scope.printRoadWaybill = function(atIndex) {
            var order = angular.copy($scope.orders[atIndex]);
            window.open("#/home/roadway-bill/" + order.id, '_blank');
        }
        
        $scope.saveOrderDetails = function(order) {
            order.deliveryDate = new Date(order.deliveryDate).getTime();
            $http.put(_env.apiUrl + '/moderatorservice/orders/update_details', order).then(function(response){   
                alert('Заказ успешно сохранен.')
            }, function(response){});
        }
            
        $scope.accounting = accounting;
        $scope.moment = moment;
    }

    function RoadwayBillCtrl($scope, $location, $http, $stateParams, _env) {
        $http
            .get(_env.apiUrl + '/moderatorservice/orders/details?id=' + $stateParams.id)
            .then(function(response){
                $scope.order = response.data;
            }, function(response){});
            
        $scope.accounting = accounting;
        $scope.moment = moment;
    }

    function CompletedOrdersCtrl($scope, $location, $http, _env) {
        
        $scope.error = null;
        
        function getOrders() {
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/orders/list?status=done&skip=' + skip + '&max=' + $scope.pager.pageSize;
            $http.get(url).then(function(response){
                $scope.orders = response.data.orders;
                $scope.pager.total = response.data.totalCount;
                $scope.error = null;
            }, function(response){
                $scope.error = response.data.msg;
            });
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 25;
        $scope.pager.getData = getOrders;
        
        getOrders();
            
        $scope.accounting = accounting;
        $scope.moment = moment;
    }

    function CanceledOrdersCtrl($scope, $location, $http, _env) {
        
        $scope.error = null;
        
        function getOrders() {
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
            
            var url = _env.apiUrl + '/moderatorservice/orders/list?status=canceled&skip=' + skip + '&max=' + $scope.pager.pageSize;
            $http.get(url).then(function(response){
                $scope.orders = response.data.orders;
                $scope.pager.total = response.data.totalCount;
                $scope.error = null;
            }, function(response){
                $scope.error = response.data.msg;
            });
        }
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 25;
        $scope.pager.getData = getOrders;
        
        getOrders();
            
        $scope.accounting = accounting;
        $scope.moment = moment;
    }
    
})();