(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('SellerSpecificationsCtrl', SellerSpecificationsCtrl)
        .controller('CreateSpecificationPopupCtrl', CreateSpecificationPopupCtrl)
        .controller('CreateSpecificationHistoryPopupCtrl', CreateSpecificationHistoryPopupCtrl)
        .controller('PrintSpecificationCtrl', PrintSpecificationCtrl)
        .controller('SpecificationHistoriesPopupCtrl', SpecificationHistoriesPopupCtrl);

	function SellerSpecificationsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

	    $scope.seller = {};
	    $scope.seller.id = $stateParams.id;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}

        $scope.accounting = accounting;
        $scope.moment = moment;

        function getSpecifications(sellerId, callback) {
            $http
                .get(_env.apiUrl + '/moderatorservice/specifications?sellerId=' + $scope.seller.id)
                .then(function(response) {
                    $scope.specifications = response.data;
					if (callback)
                    	callback();
                }, function(response) {});

        }

        getSpecifications();

        $scope.getSpecificationTotal = function(spec) {
            var totalCount = 0, totalPrice = 0;

            _.forEach(spec.products, function(product) {
                totalCount += product.quantity;
            });

            _.forEach(spec.products, function(product) {
                totalPrice += product.quantity * product.price;
            });

            return {
                'quantity': totalCount,
                'price': totalPrice
            };
        }

        $scope.removeSpecification = function(specification) {
            if (!$scope.canRemoveSpecification(specification)) return;

            var r = confirm("Удалить запись?");
            if (r == true) {
                $http.delete(_env.apiUrl + '/moderatorservice/specifications/' + specification.id).then(function(response){
                    $scope.specifications = _.filter($scope.specifications, function(spec) {
                        return spec.id != specification.id;
                    });
                }, function(response){});
            }
        }

        $scope.canRemoveSpecification = function(specification) {
            return !specification.products || specification.products.length == 0;
        }
		
		$scope.editSpecificationPopup = function(specification) {
			specification = specification || { products: [] }
			specification.sellerId = parseInt($scope.seller.id);

			var uibModalInstance = $uibModal.open({
				templateUrl: 'partials/inventory/sellers/specification/new.html',
				controller: 'CreateSpecificationPopupCtrl',
				resolve: {
					specification: function() {
						return specification;
					},
					callback: function() {
						return getSpecifications;
					}
				}
			});
		}
		
		$scope.$parent.editSpecificationPopup = $scope.editSpecificationPopup;

        $scope.addSpecificationHistoryPopup = function(specification) {
			$uibModal.open({
				templateUrl: 'partials/inventory/sellers/specification/new-version.html',
				controller: 'CreateSpecificationHistoryPopupCtrl',
				resolve: {
					specification: function() {
						return specification;
					},
					callback: function() {
						return function(newSpecificationNumber) {
							specification.number = newSpecificationNumber;
						};
					}
				}
			});
        }

        $scope.showSpecificationHistoryPopup = function(specification) {
			$uibModal.open({
				templateUrl: 'partials/inventory/sellers/specification/history.html',
				controller: 'SpecificationHistoriesPopupCtrl',
				resolve: {
					specification: function() {
						return specification;
					},
					seller: function() {
						return $scope.seller;
					}
				}
			});
        }

		$scope.onTimeSet = function (date, specification) {
			specification.modified = date.getTime();
			$http
				.put(_env.apiUrl + '/moderatorservice/specifications/' + specification.id, { 'modified': date.getTime() })
				.then(function(response) {}, function(response){});

		}

        $scope.fPrice = function(product) {
            return product.sale && product.sale < product.price ? product.sale : product.price;
        }

	}
    
    function CreateSpecificationPopupCtrl($scope, $http, $uibModalInstance, specification, callback, $uibModal, _env) {
        
		$scope.specification = specification;
    
        function getProducts() {
            $http
                .get(_env.apiUrl + '/moderatorservice/products/products_for_finance_manager?max=1000000&sellerId=' + $scope.specification.sellerId)
                .then(function(response) {
                    $scope.products = _.map(response.data.products, function(product) {
						var sProduct = _.find(specification.products, function(sProduct) { return product.id == sProduct.productId; });
						product.selected = sProduct !== undefined;
						product.quantity = sProduct ? sProduct.quantity : undefined;

						return product;
					});
                }, function(response) {});
        }
		
		getProducts();
        
        $scope.editProductPopup = function(product) {
            if (!product) {
                product = {
                    sellerId: $scope.specification.sellerId
                };
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/product/pending-product.html',
                controller: 'PendingProductPopupCtrl',
                resolve: {
                    product: function() {
                        return product;
                    },
                    callback: function() {
                        return getProducts;
                    }
                }
            });
        }

        $scope.save = function () {
			
            $scope.specification.modified = (new Date()).getTime();
			
            $scope.specification.products = _.map(_.filter(
                $scope.products,
                function(product) {
                    return product.selected;
                }), function(product) {
                    return {
                        productId: product.id,
                        quantity: product.quantity
                    };
                });
			
			if ($scope.specification.id) {
				$http
					.put(_env.apiUrl + '/moderatorservice/specifications/' + $scope.specification.id, $scope.specification)
					.then(function(response) {
						callback();
						$uibModalInstance.dismiss('cancel');
					}, function(response){});
			} else {
				$http
					.post(_env.apiUrl + '/moderatorservice/specifications', $scope.specification)
					.then(function(response) {
						callback();
						$uibModalInstance.dismiss('cancel');
					}, function(response){});
			}
        };
        
        $scope.getProduct = function(id) {
            return _.find($scope.products, function(product) { return product.id == id; });
        }
        
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

	function PrintSpecificationCtrl($scope, $location, $stateParams, $http, _env, $state) {
		
		$http.get(_env.apiUrl + '/moderatorservice/sellers/' + $stateParams.sellerId)
			.then(function(response) {
				$scope.seller = response.data;
			
				var url = _env.apiUrl + '/moderatorservice/specifications/' + $stateParams.specificationId;
				if ($stateParams.historyId) {
					url = _env.apiUrl + '/moderatorservice/specifications/' + $stateParams.specificationId + '/history/' + $stateParams.historyId;
				}

				$http.get(url)
					.then(function(response) {
						$scope.specification = response.data;
						prepareSpecificationTotal();
					}, function(response) {});

			}, function(response) {});
        
        $scope.accounting = accounting;
        $scope.moment = moment;
        
        function prepareSpecificationTotal() {
            var totalQuantity = 0, totalPrice = 0;
			
            _.forEach($scope.specification.products, function(product) {
                totalQuantity += product.quantity;
            });
            
            _.forEach($scope.specification.products, function(product) {
                totalPrice += product.quantity * fPrice(product);
            });
            
            $scope.total = {
                'quantity': totalQuantity,
                'price': totalPrice
            };
        }

        function fPrice(product) {
            return product.sale && product.sale < product.price ? product.sale : product.price;
        }

        $scope.fPrice = fPrice;
    }

    function CreateSpecificationHistoryPopupCtrl($scope, $http, $uibModalInstance, specification, callback, _env) {

        $scope.specification = specification;
        
        $scope.save = function () {
			var data = {
				specificationId: $scope.specification.id,
				number: $scope.number
			};

			$http
				.post(_env.apiUrl + '/moderatorservice/specifications/add_to_history', data)
				.then(function(response) {
					callback($scope.number);
					$uibModalInstance.dismiss('cancel');
				}, function() {});
        };
        
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }

    function SpecificationHistoriesPopupCtrl($scope, $http, $uibModalInstance, specification, seller, _env) {

        $scope.specification = specification;
		$scope.seller = seller;
		
		$http
			.get(_env.apiUrl + '/moderatorservice/specifications/' + $scope.specification.id + '/history')
			.then(function(response) {
				$scope.specifications = response.data;
			}, function() {});
        
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
		
        $scope.accounting = accounting;
        $scope.moment = moment;
        
        $scope.getSpecificationTotal = function(spec) {
            var totalCount = 0, totalPrice = 0;
            _.forEach(spec.products, function(sProduct) {
                totalCount += sProduct.quantity;
				totalPrice += sProduct.quantity * sProduct.price;
            });
            
            return {
                'quantity': totalCount,
                'price': totalPrice
            };
        }
    }

})();