(function () {
    'use strict';
    
    angular
        .module('bulavka-console')
        .controller('ParamsPopup', ParamsPopup)
        .controller('SizePopup', SizePopup)
        .directive('sizes', sizes);

    function sizes($uibModal, _env, $http) {
        return {
            templateUrl: 'partials/size/list.html',
            restrict: 'EA',
            link: function(scope, element, attrs) {
                var categoryId;
                attrs.$observe('categoryId',function() {
                    if (!attrs.categoryId) return;
                    categoryId = parseInt(attrs.categoryId);
                    getParams();
                });
                
                function getParams() {
                    if (categoryId) {
                        $http.get(_env.apiUrl + '/moderatorservice/sizes/size_param?categoryId=' + categoryId).then(function(response){
                            scope.params = response.data;
                            getSizes();
                        }, function(response) {});
                    }
                }
                
                function getSizes() {
                    if (categoryId) {
                        $http.get(_env.apiUrl + '/moderatorservice/sizes?categoryId=' + categoryId).then(function(response){
                            scope.sizes = response.data;
                        }, function(response) {});
                    }
                    
                }
                
                scope.getParamValue = function(size, paramId) {
                    var param = _.find(size.params, function(param) {
                        return param.sizeParamId == paramId;
                    });
                    
                    if (param)
                        return param.value;
                    
                    return null;
                }
                
                scope.editParams = function() {
                    $uibModal.open({
                        templateUrl: 'partials/size/params.html',
                        controller: 'ParamsPopup',
                        resolve: {
                            params: function() { return angular.copy(scope.params); },
                            categoryId: function() { return categoryId; },
                            callback: function() {
                                return getParams;
                            }
                        }
                    });
                }
                
                scope.editSize = function(size) {
                    if (!size) {
                        size = {
                            'title': '',
                            'order': (scope.sizes ? scope.sizes.length+1 : 0),
                            'categoryId': categoryId
                        };
                    }
                    
                    $uibModal.open({
                        templateUrl: 'partials/size/size.html',
                        controller: 'SizePopup',
                        resolve: {
                            size: function() {
                                var params = [];
                                var i = 0;
                                _.forEach(scope.params, function(param) {
                                    params.push({
                                        'sizeParamId': param.id,
                                        'sizeParamTitle': param.title,
                                        'value': scope.getParamValue(size, param.id)
                                    });
                                    i++;
                                });

                                var s = angular.copy(size);
                                s.params = params;
                                return s;
                            },
                            callback: function() { return getSizes; }
                        }
                    });
                    
                }
                
                scope.deleteSize = function(size) {
                    var r = confirm("Удалить размер '" + size.title + "'?");
                    if (r == true) {           
                        $http.delete(_env.apiUrl + '/moderatorservice/sizes/' + size.id).then(function(response) {
                            var index = scope.sizes.indexOf(size);
                            scope.sizes.splice(index, 1); 
                        }, function(response){});
                    }
                }
                
                scope.sizesSortableOptions = {
                    stop: function(e, ui) {
                        var i = 0;
                        var sizeOrders = _.map(scope.sizes, function(size) {
                            var order = { id: size.id, order: i };
                            i++;
                            return order;
                        });
                        $http.post(_env.apiUrl + '/moderatorservice/sizes/update_size_orders', sizeOrders);
                    }
                };
            }
        };

    }
    
    function ParamsPopup($scope, $http, $uibModalInstance, params, categoryId, callback, _env) {
        $scope.params = params;
        
        if (!$scope.params || $scope.params.length == 0) {
            $scope.params = [];
        }
        
        $scope.deleteParam = function(param) {
            var index = $scope.params.indexOf(param);
            $scope.params.splice(index, 1); 
        }
        
        $scope.addParam = function() {
            $scope.params.push({
                'title': $scope.newParamTitle,
                'order': ($scope.params ? $scope.params.length + 1 : 0),
                'categoryId': categoryId
            });
            $scope.newParamTitle = null;
        }
        
        $scope.save = function() {
            if($scope.newParamTitle) {
                $scope.addParam();
            }
            
            var data = {
                'params': $scope.params,
                'categoryId': categoryId
            }
            $http.post(_env.apiUrl + '/moderatorservice/sizes/update_size_params', data)
                .then(function(response) {
                    $uibModalInstance.dismiss('cancel');
                    callback();
                }, function(response) {});
        }
                
        $scope.sizeParamsSortableOptions = {
            stop: function(e, ui) {
                var i = 0;
                $scope.params = _.map($scope.params, function(param) {
                    param.order = i++;
                    return param;
                });
            }
        };
    }
    
    function SizePopup($scope, $http, $uibModalInstance, size, callback, _env) {
        $scope.size = size;
        $scope.save = function() {
            size.params = _.filter(size.params, function(param) {
                return param.value;
            });
            $http.post(_env.apiUrl + '/moderatorservice/sizes/update_size', size)
                .then(function(response) {
                    $uibModalInstance.dismiss('cancel');
                    callback();
                }, function(response) {});
        }
    }
    
})();