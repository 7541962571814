(function () {
    'use strict';
    
    
    var env = {};
    if(window){  
        Object.assign(env, window._env);
    }
    
    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    angular
        .module('bulavka-console', [
        'ui.router', 'ngMessages', 'ui.bootstrap', 'ui.sortable', 'ui.autocomplete', 
        'ui.select', 'ngSanitize', 'ui.bootstrap.datetimepicker', 'templates'
        ])
        .constant('_env', env)
        .config(httpProviderConfig)
        .config(routeProviderConfig)
        .run(run);
    
    function httpProviderConfig($httpProvider) {
        $httpProvider.interceptors.push(function($q) {
            return {
                'responseError': function(rejection) {
                    var defer = $q.defer();
                    if(rejection.status == 401) {
                        window.location.assign('#/login');
                    }
                    defer.reject(rejection);

                    return defer.promise;
                }
            };
        });
    }

    function routeProviderConfig($stateProvider, $urlRouterProvider) {
        
        $urlRouterProvider.otherwise("/home");

        var v = 0;

        // app routes
        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'partials/login.html',
                controller: 'LoginCtrl'
            })
            .state('home', {
                url: '/home',
                templateUrl: 'partials/home.html',
                controller: 'HomeCtrl',
                resolve: {
                    resolveCategories: function(data, $http, $q) {
                        if (!data.categories) {
                            var promise = $http.get(_env.apiUrl + '/moderatorservice/categories/list')
                            promise.success(function(categories, status, headers, conf) {
                                data.categories = categories;
                            });

                            return promise;
                        } else {
                            return $q(function(resolve, reject) {
                                resolve();
                            });
                        }

                      data.resolveCategories();
                    }
                }
            })
            .state('home.category-list', {
                url: '/category-list',
                templateUrl: 'partials/category/category-list.html',
                controller: 'CategoryListCtrl'
            })
            .state('home.category-details', {
                url: '/category-details/:id',
                templateUrl: 'partials/category/category-details.html',
                controller: 'CategoryDetailsCtrl'
            })
            .state('home.add-category', {
                url: '/add-category',
                templateUrl: 'partials/category/category-details.html',
                controller: 'CategoryDetailsCtrl'
            })
            .state('home.pending-products', {
                url: '/pending-products',
                templateUrl: 'partials/product/pending-products.html',
                controller: 'PendingProductListCtrl'
            })
            .state('home.products-list', {
                url: '/products-list',
                templateUrl: 'partials/product/products-list.html',
                controller: 'ProductListCtrl'
            })
            .state('home.add-product', {
                url: '/add-product/:id',
                templateUrl: 'partials/product/add-product.html',
                controller: 'AddProductCtrl'
            })
            .state('home.test', {
                url: '/test',
                templateUrl: 'partials/product/test.html',
                controller: 'TestCtrl'
            })
            .state('home.edit-product', {
                url: '/edit-product/:id',
                templateUrl: 'partials/product/add-product.html',
                controller: 'AddProductCtrl'
            })
            .state('home.label', {
                url: '/label/:id',
                templateUrl: 'partials/product/label.html',
                controller: 'LabelCtrl'
            })
            .state('home.editors-picks', {
                url: '/editors-picks',
                templateUrl: 'partials/editorspicks/editors-picks.html',
                controller: 'EditorsPicksCtrl'
            })
            .state('home.editors-picks/edit', {
                url: '/editors-picks/edit/:id',
                templateUrl: 'partials/editorspicks/editors-picks-edit.html',
                controller: 'EditEditorsPicksCtrl'
            })
            .state('home.editors-picks/new', {
                url: '/editors-picks/new',
                templateUrl: 'partials/editorspicks/editors-picks-edit.html',
                controller: 'EditEditorsPicksCtrl'
            })
            .state('home.banners', {
                url: '/banners',
                templateUrl: 'partials/banner/banners.html',
                controller: 'BannersCtrl'
            })
            .state('home.users', {
                url: '/users',
                templateUrl: 'partials/user/user-list.html',
                controller: 'UsersCtrl'
            })
            .state('home.user-details', {
                url: '/user-details/:id',
                templateUrl: 'partials/user/user-details.html',
                controller: 'UserDetailsCtrl'
            })
		
            .state('home.sellers2', {
                url: '/sellers2',
                templateUrl: 'partials/seller/list.html',
                controller: 'Sellers2Ctrl'
            })
            .state('home.seller-items', {
                url: '/seller-items/:id',
                templateUrl: 'partials/seller/details.html',
                controller: 'SellerDetails2Ctrl'
            })
		
		
            .state('home.sellers', {
                url: '/sellers',
                templateUrl: 'partials/inventory/sellers/index.html',
                controller: 'SellersCtrl'
            })
            .state('home.seller', {
                url: '/sellers/:id',
                templateUrl: 'partials/inventory/sellers/seller.html',
                controller: 'SellerCtrl'
            })
			.state('home.seller.details', {
                url: '/details',
                templateUrl: 'partials/inventory/sellers/detail/index.html',
                controller: 'SellerDetailsCtrl'
            })
            .state('home.seller.new', {
                url: '/new',
                templateUrl: 'partials/inventory/sellers/detail/index.html',
                controller: 'SellerDetailsCtrl'
            })
			.state('home.seller.products', {
                url: '/products',
                templateUrl: 'partials/inventory/sellers/product/index.html',
                controller: 'SellerProductsCtrl'
            })
			.state('home.seller.specifications', {
                url: '/specifications',
                templateUrl: 'partials/inventory/sellers/specification/index.html',
                controller: 'SellerSpecificationsCtrl'
            })
			.state('home.seller.returning', {
                url: '/returnings',
                templateUrl: 'partials/inventory/sellers/returning/index.html',
                controller: 'SellerReturningsCtrl'
            })
			.state('home.seller.sales', {
                url: '/sales',
                templateUrl: 'partials/inventory/sellers/sale/index.html',
                controller: 'SellerSalesCtrl'
            })
			.state('home.seller.reports', {
                url: '/reports',
                templateUrl: 'partials/inventory/sellers/report/index.html',
                controller: 'SellerReportsCtrl'
            })
			.state('home.seller.depreciations', {
                url: '/depreciations',
                templateUrl: 'partials/inventory/sellers/depreciations/index.html',
                controller: 'DepreciationsCtrl'
            })
		
            .state('specification', {
                url: '/specification/:sellerId/:specificationId/:historyId',
                templateUrl: 'partials/inventory/sellers/specification/print.html',
                controller: 'PrintSpecificationCtrl'
            })
            .state('returning', {
                url: '/returning/:sellerId/:returningId',
                templateUrl: 'partials/inventory/sellers/returning/print.html',
                controller: 'ReturningCtrl'
            })
            .state('sale-report', {
                url: '/sale-report/:sellerId/:reportId',
                templateUrl: 'partials/inventory/sellers/report/print.html',
                controller: 'PrintSaleReportCtrl'
            })
		
            .state('legal-entity-contract', {
                url: '/print/legal_entity/contract/:id',
                templateUrl: 'partials/inventory/sellers/detail/legal-entity-contract.html',
                controller: 'ContractCtrl'
            })
            .state('individual-contract', {
                url: '/print/individual/contract/:id',
                templateUrl: 'partials/inventory/sellers/detail/individual-contract.html',
                controller: 'ContractCtrl'
            })
		
            .state('home.sms', {
                url: '/sms',
                templateUrl: 'partials/user/sendsms.html',
                controller: 'SendSmsCtrl'
            })
            .state('home.brands', {
                url: '/brands',
                templateUrl: 'partials/brand/brand-list.html',
                controller: 'BrandsCtrl'       
            })
            .state('home.brand-details', {
                url: '/brand-details/:id',
                templateUrl: 'partials/brand/brand-details.html',
                controller: 'BrandCtrl'
            })
            .state('home.add-brand', {
                url: '/add-brand',
                templateUrl: 'partials/brand/brand-details.html',
                controller: 'BrandCtrl'
            })
            .state('home.shipping-settings', {
                url: '/shipping-settings',
                templateUrl: 'partials/shipping-settings/index.html',
                controller: 'ShippingSettingsCtrl'
            })
            .state('home.orders', {
                url: '/orders',
                templateUrl: 'partials/orders/orders.html',
                controller: 'OrdersCtrl'
            })
            .state('home.orders.new', {
                url: '/new',
                templateUrl: 'partials/orders/new.html',
                controller: 'NewOrdersCtrl'
            })
            .state('home.orders.payment-pending', {
                url: '/payment-pending',
                templateUrl: 'partials/orders/payment-pending.html',
                controller: 'PaymentPendingCtrl'
            })
            .state('home.orders.pending', {
                url: '/pending',
                templateUrl: 'partials/orders/pending.html',
                controller: 'FittingRoomCtrl'
            })
            .state('home.roadway-bill', {
                url: '/roadway-bill/:id',
                templateUrl: 'partials/orders/roadway-bill.html',
                controller: 'RoadwayBillCtrl'
            })
            .state('home.orders.completed', {
                url: '/completed',
                templateUrl: 'partials/orders/completed.html',
                controller: 'CompletedOrdersCtrl'
            })
            .state('home.orders.canceled', {
                url: '/canceled',
                templateUrl: 'partials/orders/canceled.html',
                controller: 'CanceledOrdersCtrl'
            })
            .state('home.accounts', {
                url: '/accounts',
                templateUrl: 'partials/account/list.html',
                controller: 'AccountsCtrl'
            })
            .state('home.my-account', {
                url: '/my-account',
                templateUrl: 'partials/account/details.html',
                controller: 'AccountCtrl'
            })
            .state('home.new-account', {
                url: '/new-account',
                templateUrl: 'partials/account/details.html',
                controller: 'AccountCtrl'
            })
            .state('home.account', {
                url: '/account/:id',
                templateUrl: 'partials/account/details.html',
                controller: 'AccountCtrl'
            })
            .state('home.stat', {
                url: '/stat',
                templateUrl: 'partials/stat/stat.html',
                controller: 'StatCtrl'
            })
            .state('home.inventory-products', {
                url: '/inventory-products',
                templateUrl: 'partials/inventory/products/index.html',
                controller: 'InventoryProductsCtrl'
            })
            .state('home.sales', {
                url: '/sales',
                templateUrl: 'partials/inventory/sales/index.html',
                controller: 'SalesCtrl'
            })
            .state('home.reports', {
                url: '/reports',
                templateUrl: 'partials/inventory/reports/index.html',
                controller: 'ReportsCtrl'
            })
            .state('report', {
                url: '/print/report/:id',
                templateUrl: 'partials/inventory/reports/print.html',
                controller: 'PrintReportCtrl'
            })
            .state('home.product-parameters', {
                url: '/product-parameters',
                templateUrl: 'partials/product-parameter/index.html',
                controller: 'ProductParametersCtrl'
            })
            .state('home.product-parameter-group', {
                url: '/product-parameters-group/:id',
                templateUrl: 'partials/product-parameter/group.html',
                controller: 'ParametersGroupCtrl'
            })
            .state('home.parameter2category', {
                url: '/parameter2category',
                templateUrl: 'partials/product-parameter/parameter2category.html',
                controller: 'Parameter2CategoryCtrl'
            });
    }

    function run($rootScope, _env) {
        
        $rootScope.getShortDateString = function(d) {
            if (!d) return '';

            var value = new Date(d);
            return value.getDate() + ' ' + getMonthTitleByNumber(value.getMonth()) + ' ' + value.getFullYear();
        }

        $rootScope.getFullDateString = function(date) {
            if (!date) return '';

            var value = new Date(date);
            return value.getDate() + ' ' + getMonthTitleByNumber(value.getMonth()) + ' ' + value.getFullYear() + ' ' + value.getHours() + ':' +  (value.getMinutes()<10?'0':'')  + value.getMinutes();
        }

        function getMonthTitleByNumber(number) {
            var title = '';

            if (number == 0) title = 'января';
            if (number == 1) title = 'февраля';
            if (number == 2) title = 'марта';
            if (number == 3) title = 'апреля';
            if (number == 4) title = 'мая';
            if (number == 5) title = 'июня';
            if (number == 6) title = 'июля';
            if (number == 7) title = 'августа';
            if (number == 8) title = 'сентября';
            if (number == 9) title = 'октября';
            if (number == 10) title = 'ноября';
            if (number == 11) title = 'декабря';

            return title;
        }

        $rootScope.getImageWithDefault = function(imageUrl) {
            if (imageUrl)
                return imageUrl;
            else
                return "https://media.bulavka.uz/other/placeholder";
        }

        $rootScope.commafy = function (num) {
            if (!num) return '0';

            var str = num.toString().split('.');
            if (str[0].length >= 4) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
            }
            if (str[1] && str[1].length >= 5) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }

            return str.join('.');
        }

        $rootScope.Math = window.Math;

        $rootScope.status2LocalStatus = function(status) {
            if (status == 'published') return 'В продаже';
            if (status == 'deleted') return 'Удален';
            if (status == 'sold') return 'Продан';
            return status;
        }

        $rootScope.condition2LocalCondition = function(condition) {
            if (condition == 'gently_used') return 'Хороший';
            if (condition == 'like_new') return 'Как новый';
            if (condition == 'not_used') return 'Новый';

            return condition;
        }
        

        if (_env.routeProvider.debug) {
            $rootScope.$on('$stateChangeStart',function(event, toState, toParams, fromState, fromParams){
                console.log('$stateChangeStart to '+toState.to+'- fired when the transition begins. toState,toParams : \n',toState, toParams);
            });

            $rootScope.$on('$stateChangeError',function(event, toState, toParams, fromState, fromParams){
                console.log('$stateChangeError - fired when an error occurs during transition.');
                console.log(arguments);
            });

            $rootScope.$on('$stateChangeSuccess',function(event, toState, toParams, fromState, fromParams){
                console.log('$stateChangeSuccess to '+toState.name+'- fired once the state transition is complete.');
            });

            $rootScope.$on('$viewContentLoaded',function(event){
                console.log('$viewContentLoaded - fired after dom rendered',event);
            });

            $rootScope.$on('$stateNotFound',function(event, unfoundState, fromState, fromParams){
                console.log('$stateNotFound '+unfoundState.to+'  - fired when a state cannot be found by its name.');
                console.log(unfoundState, fromState, fromParams);
            });
        }
    }
    
})();
