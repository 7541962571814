(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('InventoryProductsCtrl', InventoryProductsCtrl);

    function InventoryProductsCtrl($scope, $location, $stateParams, $http, _env, $uibModal, data) {

        $scope.filter = {
            searchKey: null
        };

        function getData(refresh) {
            if (refresh) {
                $scope.pager.currentPage = 1;
                $scope.products = [];
                $scope.pager.total = 0;
            }
            var skip = 0;
            if ($scope.pager.currentPage > 0)
                skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;

            var url = _env.apiUrl + '/moderatorservice/products/products_for_finance_manager?skip=' + skip + '&max=' + $scope.pager.pageSize;

            if ($scope.filter.searchKey)
                url = url + '&productId=' + $scope.filter.searchKey;

            $http
                .get(url)
                .then(function(response) {
                    $scope.products = response.data.products;
                    $scope.pager.total = response.data.totalCount;
                }, function(response){});
        }

        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 100;
        $scope.pager.getData = getData;
        $scope.getData = getData;

        $scope.pager.getData();

        $scope.doSearch = function() {
            $scope.filter.searchKey = $scope.searchKey;
            $scope.pager.getData(true);
        }

        $scope.clearSearch = function() {
            $scope.searchKey = '';
            $scope.filter.searchKey = null;
            $scope.pager.getData(true);
        }


        $scope.getDateStr = function(date) {
        	return new Date(date).toLocaleString();
        };

        $scope.editProductPopup = function(product) {
            if (!product) {
                product = {
                    sellerId: $scope.seller.id
                };
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/product/pending-product.html',
                controller: 'PendingProductPopupCtrl',
                resolve: {
                    product: function() {
                        return product;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.depreciationPopup = function(product) {
            if (!product) {
                product = {
                    sellerId: $scope.seller.id
                };
            }

            var uibModalInstance = $uibModal.open({
                templateUrl: 'partials/inventory/sellers/depreciations/new.html',
                controller: 'DepreciationPopupCtrl',
                resolve: {
                    product: function() {
                        return product;
                    },
                    callback: function() {
                        return getData;
                    }
                }
            });
        }

        $scope.accounting = accounting;
        $scope.moment = moment;

		$scope.fPrice = function(item) {
			return item.sale ? item.sale : item.price;
		}
    }

})();