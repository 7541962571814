(function (window) {
    window._env = window._env || {};

    // API url
//    window._env.apiUrl = 'https://api.bulavka.uz/bulavka-rpc';
    window._env.apiUrl = '/bulavka-rpc';
    window._env.routeProvider = {
        debug: false
    }
    
    console.log('Local environment accepted');

}(this));