(function () {
    'use strict';

    angular
        .module('bulavka-console')
        .controller('EditorsPicksCtrl', EditorsPicksCtrl)
        .controller('EditEditorsPicksCtrl', EditEditorsPicksCtrl);
    
    function EditorsPicksCtrl($scope, $location, $http, _env) {
        $http.get(_env.apiUrl + '/moderatorservice/products/editors_picks/list').then(function(response){
            $scope.editorPicks = response.data;
        }, function(response){});
        
        $scope.viewEditorPickDetails = function(editorPick, index) {
            $location.path('/home/editors-picks/edit/' + editorPick.id);
        }
        
        $scope.sortableOptions = {
            stop: function(e, ui) {
                var i = 0;
                var itemsOrders = _.map($scope.editorPicks, function(editorPick) {
                    var order = { id: editorPick.id, order: i };
                    i++;
                    return order;
                });
                $http.put(_env.apiUrl + '/moderatorservice/products/editors_picks/update_orders', itemsOrders);
            }
        };
    }

    function EditEditorsPicksCtrl($scope, $location, $http, $stateParams, _env) {
        
        var id = $stateParams.id;
        
        $scope.editorPick = {};
        
        function get(id) {
            $http.get(_env.apiUrl + '/moderatorservice/products/editors_picks/details?id=' + id).then(function(response){
                    $scope.editorPick = response.data.editorPick;
                    $scope.products = response.data.products;
                    $scope.mainProducts = response.data.mainProducts;
            }, function(response){});
        }
        
        if (id) {
            get(id);
        }
        
        $scope.remove = function() {
            $http.delete(_env.apiUrl + '/moderatorservice/products/editors_picks?id=' + $scope.editorPick.id).then(function(response){
                $location.path("/home/editors-picks");   
            }, function(response){});
        }
        
        $scope.save = function() {
            if (!$scope.editorPick.categoryTitle) {
                alert('Укажите название для категорий');
                return;
            }
            
            if (!$scope.editorPick.title) {
                alert('Укажите название для подборки');
                return;
            }
            
            $scope.editorPick.description = "";
            $scope.editorPick.isActive = true;
            
            if (!$scope.editorPick.image || !$scope.editorPick.imageWide) {
                alert('Выберите фото для подборки в двух вариантах');
                return;
            };

            $http
                .post(_env.apiUrl + '/moderatorservice/products/editors_picks/update', $scope.editorPick).then(function(response) {
                    if ($scope.editorPick.id) {
                        $location.path("/home/editors-picks");
                    } else {
                        $scope.editorPick.id = response.data.id;
                        alert('Подборка успешно добавлена, подберите товары.')
                    }
                }, function(response){
                });
        }
        
        var logo = $($('#image-file')[0]);
        logo.bind('change', function(){
            var file = $('#image-file')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=editors_picks', c, config).then(function(response) {
                        $scope.editorPick.image= response.data.image;
                    }, function(response){
                        alert(response.data);									
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        var logo = $($('#image-file-wide')[0]);
        logo.bind('change', function(){
            var file = $('#image-file-wide')[0].files[0];
                var fr = new FileReader();
                fr.onload = function(readerEvt) {
                    var c = readerEvt.target.result;
                    var fd = new FormData();
                    fd.append('file', c);
                    var config = {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': file.type}
                    };
                    $http.post(_env.apiUrl + '/filestorage/media/upload' + '?type=editors_picks', c, config).then(function(response) {
                        $scope.editorPick.imageWide= response.data.image;
                    }, function(response){
                        alert(response.data);
                    });
                };					
                fr.readAsArrayBuffer(file);
        });
        
        $scope.addProduct = function(product, isMain) {
            var data = {
                productId: product.id, 
                editorPickId: $scope.editorPick.id,
                isMain: isMain ? isMain : false
            }
            
            if (isMain) {
                data.order = $scope.mainProducts.length;
            }
            
            $http.post(_env.apiUrl + '/moderatorservice/products/editors_picks/update_item', data).then(function(response){
                if (isMain)
                    $scope.mainProducts.push(product)
                else
                    $scope.products.push(product)
            }, function(response){});
        }
        
        $scope.removeProduct = function(index, isMain) {
            var productId;
            if (isMain)
                productId = $scope.mainProducts[index].id;
            else
                productId = $scope.products[index].id;
            
            $http.delete(_env.apiUrl + '/moderatorservice/products/editors_picks/item?id=' + $scope.editorPick.id + '&productId=' + productId).then(function(response){
                if (isMain)
                    $scope.mainProducts.splice(index, 1);
                else
                    $scope.products.splice(index, 1);
            }, function(response){});
        }
        
        $scope.viewProductDetails = function(product) {
        	$location.path("/home/edit-product/" + product.id);
        };
        
        function getProducts() {
            if ($scope.searchKey) {
                var skip = 0;
                if ($scope.pager.currentPage > 0) skip = ($scope.pager.currentPage - 1) * $scope.pager.pageSize;
                var max = $scope.pager.pageSize;
                $http.get(_env.apiUrl + '/moderatorservice/products/search?skip=' + skip + '&max=' + max + '&searchKey=' + $scope.searchKey).then(function(response){
                    $scope.searchedProducts = response.data.products;
                    $scope.pager.total = response.data.totalCount;
                }, function(response){});
            }
        }
        
        // search
        
        $scope.pager = {};
        $scope.pager.currentPage = 1;
        $scope.pager.total = 0;
        $scope.pager.pageSize = 10;
        $scope.pager.getData = getProducts;
        
        $scope.getProducts = getProducts;
        
        $scope.clearSearch = function() {
            $scope.searchKey = null;
            $scope.searchedProducts = [];
            $scope.pager.total = 0;
            
        }
        
        $scope.changeMainGiftOrder = function(upIndex, downIndex) {
            var itemUp = $scope.mainProducts[upIndex];
            var itemDown = $scope.mainProducts[downIndex];
            $scope.mainProducts[downIndex] = itemUp;
            $scope.mainProducts[upIndex] = itemDown;
            
            var i = 0;
            var orders = _.map($scope.mainProducts, function(product) {
                var order = {
                    productId: product.id,
                    value: i
                }
                i++;
                return order;
            });
            
            $http.post(_env.apiUrl + '/moderatorservice/products/editors_picks/update_main_item_order', { editorPickId: $scope.editorPick.id, orders: orders }).then(function(response) {
            }, function(response){});
        }
    }
})();